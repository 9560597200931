<div class="general-design-container">
  <!-- ToDo: color palette calculation based on this entries -->
  <!-- as long as this calculation is not available this section is hidden -->
  <div *ngIf="permissionStates?.ngCustomColorsAdmin" class="section-wrapper fit-content">
    <h2>
      <span i18n="@@general_conf_theme_colors">Theme Colors</span>
    </h2>
    <div class="color-picker-wrapper">
      <!-- Main Color-->
      <div class="color-picker-information">
        <div class="color-picker-label">
          <h2>
            <span i18n="@@general_conf_main_color_title">Main Color</span>
          </h2>
          <span>{{mainColor}}</span>
        </div>

        <button (click)="activateColorPicker('mainColor')" [ngStyle]="{'background-color': mainColor}"
                mat-fab></button>
      </div>
      <div *ngIf="mainColorPickerActive" class="color-wrapper">
        <color-sketch (onChangeComplete)="changeMainColor($event)" [color]="mainColor"
                      class="fade"></color-sketch>
        <button (click)="deactivateColorPickerAndMarkForUpdate('mainColor')" class="fade" color="primary"
                mat-flat-button>
          <span i18n="@@general_conf_color_set_button">Set color</span>
        </button>
        <button (click)="deactivateColorPicker('mainColor')" class="fade cancel-button" color="warn"
                mat-flat-button>
          <span i18n="@@general_conf_color_cancel_button">Cancel</span>
        </button>
      </div>
    </div>

    <div *ngIf="mainColorPreview.length > 0" class="theme-preview">
      <mat-chip-listbox>
        <mat-chip-option *ngFor="let color of mainColorPreview" [style.backgroundColor]="color.hex"
                  [style.color]="color.darkContrast ? '#000': '#fff'">{{color.hex}}</mat-chip-option>
      </mat-chip-listbox>
    </div>

    <div class="color-picker-wrapper">
      <!-- Accent Color-->
      <div class="color-picker-information">
        <div class="color-picker-label">
          <h2>
            <span i18n="@@general_conf_accent_color_title">Accent Color</span>
          </h2>
          <span>{{accentColor}}</span>
        </div>

        <button (click)="activateColorPicker('accentColor')" [ngStyle]="{'background-color': accentColor}"
                mat-fab></button>
      </div>
      <div *ngIf="accentColorPickerActive" class="color-wrapper">
        <color-sketch (onChangeComplete)="changeAccentColor($event)" [color]="accentColor"
                      class="fade"></color-sketch>
        <button (click)="deactivateColorPickerAndMarkForUpdate('accentColor')" class="fade" color="primary"
                mat-flat-button>
          <span i18n="@@general_conf_color_set_button">Set color</span>
        </button>
        <button (click)="deactivateColorPicker('accentColor')" class="fade cancel-button" color="warn"
                mat-flat-button>
          <span i18n="@@general_conf_color_cancel_button">Cancel</span>
        </button>
      </div>
    </div>

    <div *ngIf="accentColorPreview.length > 0" class="theme-preview">
      <mat-chip-listbox>
        <mat-chip-option *ngFor="let color of accentColorPreview" [style.backgroundColor]="color.hex"
                  [style.color]="color.darkContrast ? '#000': '#fff'">{{color.hex}}</mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>

  <!-- next section-->
  <div class="section-wrapper">
    <h2>
      <span i18n="@@general_conf_theme_current_logo">Current Logo</span>
    </h2>
    <div class="rag-separator">
      <ng-container *ngFor="let image of images$ | async">
        <div *ngIf="image.fileId === logoId" class="current-logo">
          <img alt="current logo" src="{{image.link}}"/>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- next section-->
  <div class="section-wrapper">
    <h2>
      <span i18n="@@general_conf_theme_select_logo">Select Logo</span>
    </h2>
    <div class="button-wrapper">
      <button (click)="showLogoImages()" [ngClass]="{'hide': isLogoSelectionActive}"
              color="primary"
              mat-flat-button>
        <span i18n="@@general_conf_show_all_images_button">Show</span>
      </button>
      <button (click)="hideLogoImages()" [ngClass]="{'hide': !isLogoSelectionActive}"
              color="warn"
              mat-flat-button>
        <span i18n="@@general_conf_hide_all_images_button">Hide</span>
      </button>

      <rag-file-upload (fileUploadFinished)="reloadImages()" [apiUrlKey]="'PostAccountImage'"
                       [maxFileSize]="1"></rag-file-upload>
    </div>


    <div *ngIf="isLogoSelectionActive" class="logo-wrapper">
      <mat-card appearance="outlined" *ngFor="let image of images$ | async"
                [ngClass]="{'current-logo': image.fileId === logoId}" class="rag-card fade">
        <mat-card-header>
          <mat-card-title class="hyphenate title">{{image.fileName}}</mat-card-title>
          <mat-card-subtitle *ngIf="image.fileId === logoId" class="hyphenate title">
            <span i18n="@@general_conf_current_logo">Current logo</span>
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="image.fileId !== logoId" class="title">&nbsp;</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <img alt="logo" mat-card-image src="{{image.link}}"/>
        </mat-card-content>

        <mat-card-actions>
          <button (click)="changeLogo(image.fileId)" color="primary"
                  i18n-matTooltip="@@account_design_select_button"
                  mat-icon-button
                  matTooltip="Select"
                  matTooltipPosition="above">
            <mat-icon svgIcon="image-filter-center-focus"></mat-icon>
          </button>
          <button (click)="openDialog(image.id)" color="warn" i18n-matTooltip="@@account_design_delete_button"
                  mat-icon-button
                  matTooltip="Delete"
                  matTooltipPosition="above">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div class="section-wrapper">
    <!-- Page Title -->
    <h2>
      <span i18n="@@general_conf_set_page_title">Set Page Title</span>
    </h2>
    <div>
      <mat-form-field class="small-input-field" appearance="outline">
        <mat-label>
          <span i18n="@@general_conf_page_title_label">Page Title</span>
        </mat-label>
        <input (input)="isPageTitleDirty()" [(ngModel)]="pendingPageTitle" matInput type="text"/>
      </mat-form-field>
    </div>
    <button (click)="postStyleSettings('pageTitle', pendingPageTitle)" *ngIf="pageTitleIsDirty" color="primary"
            mat-flat-button>
      <span i18n="@@general_conf_save_page_title_button">Save Page Title</span>
    </button>
  </div>

  <div class="section-wrapper">
    <!-- Customisation of my courses -->
    <h2>
      <span i18n="@@general_conf_set_name_my_contents">Set name for my contents</span>
    </h2>
    <div class="multiple-inputs">
      <ng-container *ngFor="let language of languages; let i = index">
        <mat-form-field class="small-input-field" appearance="outline">
          <mat-label>
            <span>{{language.title | localeText}}</span>
          </mat-label>
          <input (input)="isMyContentsNameDirty()" [(ngModel)]="pendingMyContentsName[language.key]" matInput type="text"/>
        </mat-form-field>
      </ng-container>
    </div>
    <button (click)="postStyleSettings('myContentsName', pendingMyContentsName)" *ngIf="myContentsNameIsDirty"
            color="primary"
            mat-flat-button>
      <span i18n="@@general_conf_save_my_contents_button">Save My Contents</span>
    </button>
  </div>

  <!-- Preparation for Favicon -->
  <!-- next section-->
  <div class="section-wrapper">
    <h2>
      <span i18n="@@general_conf_theme_current_favicon">Current Favicon</span>
    </h2>
    <div class="rag-separator">
      <ng-container *ngFor="let image of images$ | async">
        <div *ngIf="image.fileId === faviconId" class="current-logo">
          <img alt="current logo" src="{{image.link}}"/>
        </div>
      </ng-container>
      <!--      ToDo change default favicon to reflact -->
      <div *ngIf="faviconId === 0" class="current-logo">
        <img alt="current logo" src="assets/favicon/iLearn24/ilearn24-favicon.ico"/>
      </div>
    </div>
  </div>

  <!-- next section-->
  <div class="section-wrapper">
    <h2>
      <span i18n="@@general_conf_theme_select_favicon">Select Favicon</span>
    </h2>

    <div class="button-wrapper">
      <button (click)="resetFavicon()"
              mat-flat-button>
        <span i18n="@@general_conf_default_favicon_button">Default Favicon</span>
      </button>
    </div>

    <div class="button-wrapper">
      <button (click)="showFaviconImages()" [ngClass]="{'hide': isFaviconSelectionActive}"
              color="primary"
              mat-flat-button>
        <span i18n="@@general_conf_show_all_images_button">Show</span>
      </button>
      <button (click)="hideFaviconImages()" [ngClass]="{'hide': !isFaviconSelectionActive}"
              color="warn"
              mat-flat-button>
        <span i18n="@@general_conf_hide_all_images_button">Hide</span>
      </button>

      <rag-file-upload (fileUploadFinished)="reloadImages()" [apiUrlKey]="'PostAccountImage'"
                       [maxFileSize]="0.5"></rag-file-upload>
    </div>


    <div *ngIf="isFaviconSelectionActive" class="logo-wrapper">
      <mat-card appearance="outlined" *ngFor="let image of images$ | async"
                [ngClass]="{'current-logo': image.fileId === faviconId}" class="rag-card fade">
        <mat-card-header>
          <mat-card-title class="hyphenate title">{{image.fileName}}</mat-card-title>
          <mat-card-subtitle *ngIf="image.fileId === faviconId" class="hyphenate title"
                             i18n="@@general_conf_current_favicon">Current favicon
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <img alt="logo" mat-card-image src="{{image.link}}"/>
        </mat-card-content>

        <mat-card-actions>
          <button (click)="changeFavicon(image.fileId)" i18n-matTooltip="@@account_design_select_button"
                  mat-icon-button
                  matTooltip="Select"
                  matTooltipPosition="above">
            <mat-icon svgIcon="image-filter-center-focus"></mat-icon>
          </button>
          <button (click)="changeFavicon(image.id)" i18n-matTooltip="@@account_design_delete_button" mat-icon-button
                  matTooltip="Delete"
                  matTooltipPosition="above">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <!-- next section-->
  <div class="section-wrapper checkboxes">
    <h2>
      <span i18n=@@design>Design</span>
    </h2>
    <mat-checkbox (change)="isNewNavigationEnabledDirty()" [(ngModel)]="pendingShowNewNavigation">
      <span i18n="@@admin_show_new_navigation">Show new navigation</span>
    </mat-checkbox>

    <button
      *ngIf="showNewNavigationEnabledIsDirty"
      mat-flat-button
      type="button"
      color="primary"
      class="fit-content"
      (click)="postStyleSettings('showNewNavigation', pendingShowNewNavigation)"
    >
      <span i18n="@@global_save">Save</span>
    </button>

    <mat-checkbox class="checkbox-margin" (change)="isFullScreenSizeEnabledDirty()" [(ngModel)]="pendingFullScreenSizeEnabled">
        <span i18n="@@admin_use_entire_screen_size">Use the entire screen size</span>
    </mat-checkbox>

    <button
      *ngIf="fullScreenSizeEnabledIsDirty"
      mat-flat-button
      type="button"
      color="primary"
      class="fit-content"
      (click)="postStyleSettings('fullScreenSizeEnabled', pendingFullScreenSizeEnabled)"
    >
      <span i18n="@@global_save">Save</span>
    </button>
  </div>
</div>
