import {
  AnyObject,
  Core,
  FileInfo,
  MessageAccountInfo,
  NumberedAnyObject,
  Principal,
  TargetGroup,
  TransientView,
} from '../../../../../core/core.types';
import { Translation } from '../../../../../core/translation/translation.types';
import { Observable } from 'rxjs';
import { MacroContextSettings } from 'src/app/core/macros.types';
import { NamedDistributable } from '../../../../../core/content/content.types';
import { NotificationIdentifiable, SubTargetOptions } from '../../../admin-event-actions/admin-notifications/admin-notifications.types';

export interface Signature {
  title: string;
  id: number;
  content: string;
  type?: string;
  macro: string;
}

export interface NotificationAddType {
  moduleId: number;
  eventId: number;
  name: string;
  nameOverride?: AnyObject<string>;
  tooltip?: string;
}

export interface NotificationAddGroup {
  name: string;
  notifications: NotificationAddType[];
}

export const getNotificationById = (eventId: number) =>
  CONTENT_NOTIFICATIONS_TYPES.reduce((pV, notiGroup) => {
    if ( pV === undefined ) {
      pV = notiGroup.notifications.find(noti => noti.eventId === eventId);
    }
    return pV;
  }, undefined as NotificationAddType);

export const getNotificationNameById = (eventId: number) =>
  CONTENT_NOTIFICATIONS_TYPES.reduce((pV, notiGroup) => {
    if ( pV === undefined ) {
      pV = notiGroup.notifications.find(noti => noti.eventId === eventId)?.name;
    }
    return pV;
  }, undefined as string);

export const CONTENT_NOTIFICATIONS_TYPES: NotificationAddGroup[] = [ {
  name: $localize`:@@header_nav_admin_offline:Events`,
  notifications: [
  /*{
    name: $localize`:@@ehs.15.150001_name:Präsenztraining zugewiesen`,
    eventId: 150001,
    moduleId: 15,
    tooltip: $localize`:@@ehs.15.150001_name:Teilnehmer wurde dem Pr\u00E4senztraining zugewiesen,`
  },
  {
    name: $localize`:@@ehs.15.150002_name:Pr\u00E4senztraining entzogen`,
    eventId: 150002,
    moduleId: 15,
    tooltip: $localize`:@@ehs.15.150002_name:Der Pr\u00E4senztraining wurde dem Teilnehmer entzogen,`
  },
  {
    name: $localize`:@@ehs.15.150003_name:Pr\u00E4senztraining gesperrt`,
    eventId: 150003,
    moduleId: 15,
    tooltip: $localize`:@@ehs.15.150003_name:Dieses Pr\u00E4senztraining wurde gesperrt,`
  },
  {
    name: $localize`:@@ehs.15.150004_name:Pr\u00E4senztraining entsperrt`,
    eventId: 150004,
    moduleId: 15,
    tooltip: $localize`:@@ehs.15.150004_name:Dieses Pr\u00E4senztraining wurde entsperrt,`
  },
  {
    name: $localize`:@@ehs.15.150005_name:Pr\u00E4senztraining entsperrt`,
    eventId: 150005,
    moduleId: 15,
    tooltip: $localize`:@@ehs.15.150005_name:Dieses Pr\u00E4senztraining wurde entsperrt,`
  },
  {
    name: $localize`:@@ehs.15.150006_name:Pr\u00E4senztraining gel\u00F6scht`,
    eventId: 150006,
    moduleId: 15,
    tooltip: $localize`:@@ehs.15.150006_name:Dieses Pr\u00E4senztraining wurde gel\u00F6scht,`
  },
  {
    name: $localize`:@@ehs.15.150007_name:Pr\u00E4senztraining storniert`,
    eventId: 150007,
    moduleId: 15,
    tooltip: $localize`:@@ehs.15.150007_name:Dieses Pr\u00E4senztraining vom Teilnehmer storniert,`
  },
  {
    name: $localize`:@@ehs.151.151010_name:Wunschtermin angefragt`,
    eventId: 151010,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151010_name:Der Lerner hat einen Wunschtermin angefragt,`
  },
  {
    name: $localize`:@@ehs.151.151012_name:Wunschtermin angenommen`,
    eventId: 151012,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151012_name:Der Wunschtermin wurde angenommen,`
  },
  {
    name: $localize`:@@ehs.151.151011_name:Wunschtermin abgelehnt`,
    eventId: 151011,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151011_name:Der Wunschtermin wurde abgelehnt,`
  },
  {
    name: $localize`:@@ehs.151.151001_name:Seminar zugewiesen`,
    eventId: 151001,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151001_name:Seminar wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.151.151003_name:Seminar Termin zugewiesen`,
    eventId: 151003,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151003_name:Termin zum Seminar wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.151.151002_name:Seminar entzogen`,
    eventId: 151002,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151002_name:Seminar wurde entzogen,`
  },
  {
    name: $localize`:@@ehs.151.151005_name:Seminar Termin abgesagt`,
    eventId: 151005,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151005_name:Termin zum Seminar wurde abgesagt,`
  },
  {
    name: $localize`:@@ehs.151.151004_name:Seminar Termin verschoben`,
    eventId: 151004,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151004_name:Termin zum Seminar wurde verschoben,`
  },
  {
    name: $localize`:@@ehs.151.151007_name:Seminar abgeschlossen`,
    eventId: 151007,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151007_name:Das Seminar wurde abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.151.151006_name:Seminar teilgenommen`,
    eventId: 151006,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151006_name:Am Seminar wurde teilgenommen,`
  },
  {
    name: $localize`:@@ehs.151.151009_name:Seminar entsperrt`,
    eventId: 151009,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151009_name:Dieses Seminar wurde entsperrt,`
  },
  {
    name: $localize`:@@ehs.151.151008_name:Seminar gesperrt`,
    eventId: 151008,
    moduleId: 151,
    tooltip: $localize`:@@ehs.151.151008_name:Dieses Seminar wurde gesperrt,`
  },
  {
    name: $localize`:@@ehs.152.152010_name:Wunschtermin angefragt`,
    eventId: 152010,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152010_name:Der Lerner hat einen Wunschtermin angefragt,`
  },
  {
    name: $localize`:@@ehs.152.152003_name:Gespr\u00E4ch Termin zugewiesen`,
    eventId: 152003,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152003_name:Termin zum Gespr\u00E4ch wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.152.152002_name:Gespr\u00E4ch entzogen`,
    eventId: 152002,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152002_name:Gespr\u00E4ch wurde entzogen,`
  },
  {
    name: $localize`:@@ehs.152.152001_name:Gespr\u00E4ch zugewiesen`,
    eventId: 152001,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152001_name:Gespr\u00E4ch wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.152.152007_name:Gespr\u00E4ch abgeschlossen`,
    eventId: 152007,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152007_name:Das Gespr\u00E4ch wurde abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.152.152006_name:Gespr\u00E4ch teilgenommen`,
    eventId: 152006,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152006_name:Am Gespr\u00E4ch wurde teilgenommen,`
  },
  {
    name: $localize`:@@ehs.152.152005_name:Gespr\u00E4ch Termin abgesagt`,
    eventId: 152005,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152005_name:Termin zum Gespr\u00E4ch wurde abgesagt,`
  },
  {
    name: $localize`:@@ehs.152.152004_name:Gespr\u00E4ch Termin verschoben`,
    eventId: 152004,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152004_name:Termin zum Gespr\u00E4ch wurde verschoben,`
  },
  {
    name: $localize`:@@ehs.152.152009_name:Gespr\u00E4ch entsperrt`,
    eventId: 152009,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152009_name:Dieses Gespr\u00E4ch wurde entsperrt,`
  },
  {
    name: $localize`:@@ehs.152.152008_name:Gespr\u00E4ch gesperrt`,
    eventId: 152008,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152008_name:Dieses Gespr\u00E4ch wurde gesperrt,`
  },
  {
    name: $localize`:@@ehs.152.152012_name:Wunschtermin angenommen`,
    eventId: 152012,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152012_name:Der Wunschtermin wurde angenommen,`
  },
  {
    name: $localize`:@@ehs.152.152011_name:Wunschtermin abgelehnt`,
    eventId: 152011,
    moduleId: 152,
    tooltip: $localize`:@@ehs.152.152011_name:Der Wunschtermin wurde abgelehnt,`
  },
  {
    name: $localize`:@@ehs.153.153001_name:Dokument(Download) zugewiesen`,
    eventId: 153001,
    moduleId: 153,
    tooltip: $localize`:@@ehs.153.153001_name:Dokument(Download) wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.153.153003_name:Dokument(Download) abgeschlossen`,
    eventId: 153003,
    moduleId: 153,
    tooltip: $localize`:@@ehs.153.153003_name:Der Dokument(Download) wurde abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.153.153002_name:Dokument(Download) entzogen`,
    eventId: 153002,
    moduleId: 153,
    tooltip: $localize`:@@ehs.153.153002_name:Dokument(Download) wurde entzogen,`
  },
  {
    name: $localize`:@@ehs.153.153005_name:Dokument(Download) entsperrt`,
    eventId: 153005,
    moduleId: 153,
    tooltip: $localize`:@@ehs.153.153005_name:Dieser Dokument(Download) wurde entsperrt,`
  },
  {
    name: $localize`:@@ehs.153.153004_name:Dokument(Download) gesperrt`,
    eventId: 153004,
    moduleId: 153,
    tooltip: $localize`:@@ehs.153.153004_name:Dieser Dokument(Download) wurde gesperrt,`
  },
  {
    name: $localize`:@@ehs.154.154002_name:Side-by-side entzogen`,
    eventId: 154002,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154002_name:Side-by-side wurde entzogen,`
  },
  {
    name: $localize`:@@ehs.154.154003_name:Side-by-side Termin zugewiesen`,
    eventId: 154003,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154003_name:Termin zum Side-by-side wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.154.154001_name:Side-by-side zugewiesen`,
    eventId: 154001,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154001_name:Side-by-side wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.154.154011_name:Wunschtermin abgelehnt`,
    eventId: 154011,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154011_name:Der Wunschtermin wurde abgelehnt,`
  },
  {
    name: $localize`:@@ehs.154.154012_name:Wunschtermin angenommen`,
    eventId: 154012,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154012_name:Der Wunschtermin wurde angenommen,`
  },
  {
    name: $localize`:@@ehs.154.154010_name:Wunschtermin angefragt`,
    eventId: 154010,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154010_name:Der Lerner hat einen Wunschtermin angefragt,`
  },
  {
    name: $localize`:@@ehs.154.154008_name:Side-by-side gesperrt`,
    eventId: 154008,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154008_name:Dieses Side-by-side wurde gesperrt,`
  },
  {
    name: $localize`:@@ehs.154.154009_name:Side-by-side entsperrt`,
    eventId: 154009,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154009_name:Dieses Side-by-side wurde entsperrt,`
  },
  {
    name: $localize`:@@ehs.154.154006_name:Side-by-side teilgenommen`,
    eventId: 154006,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154006_name:Am Side-by-side wurde teilgenommen,`
  },
  {
    name: $localize`:@@ehs.154.154007_name:Side-by-side abgeschlossen`,
    eventId: 154007,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154007_name:Der Side-by-side wurde abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.154.154004_name:Side-by-side Termin verschoben`,
    eventId: 154004,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154004_name:Termin zum Side-by-side wurde verschoben,`
  },
  {
    name: $localize`:@@ehs.154.154005_name:Side-by-side Termin abgesagt`,
    eventId: 154005,
    moduleId: 154,
    tooltip: $localize`:@@ehs.154.154005_name:Termin zum Side-by-side wurde abgesagt,`
  },
  {
    name: $localize`:@@ehs.155.155001_name:Virco zugewiesen`,
    eventId: 155001,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155001_name:Virco wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.155.155002_name:Virco entzogen`,
    eventId: 155002,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155002_name:Virco wurde entzogen,`
  },
  {
    name: $localize`:@@ehs.155.155003_name:Virco abgeschlossen`,
    eventId: 155003,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155003_name:Virco wurde abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.155.155004_name:Virco gesperrt`,
    eventId: 155004,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155004_name:Diese Virco wurde gesperrt,`
  },
  {
    name: $localize`:@@ehs.155.155005_name:Virco entsperrt`,
    eventId: 155005,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155005_name:Diese Virco wurde entsperrt,`
  },
  {
    name: $localize`:@@ehs.155.155010_name:Wunschermin angefragt`,
    eventId: 155010,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155010_name:Der Lerner hat einen Wunschtermin angefragt,`
  },
  {
    name: $localize`:@@ehs.155.155011_name:Wunschtermin abgelehnt`,
    eventId: 155011,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155011_name:Der Wunschtermin wurde abgelehnt,`
  },
  {
    name: $localize`:@@ehs.155.155012_name:Wunschtermin angenommen`,
    eventId: 155012,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155012_name:Der Wunschtermin wurde angenommen,`
  },
  {
    name: $localize`:@@ehs.155.155006_name:Virco verschoben`,
    eventId: 155006,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155006_name:Diese Virco wurde verschoben,`
  },
  {
    name: $localize`:@@ehs.155.155008_name:An Virco teilgenommen`,
    eventId: 155008,
    moduleId: 155,
    tooltip: $localize`:@@ehs.155.155008_name:An dieser Virco wurde teilgenommen,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156012_name:An Termin teilgenommen und Pr\u00FCfung bestanden`,
    eventId: 156012,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156012_name:An Termin teilgenommen und Pr\u00FCfung bestanden,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156011_name:Termin hat offene Vorbedingungen`,
    eventId: 156011,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156011_name:Termin hat offene Vorbedingungen in einem Curriculum,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156014_name:Termin wurde storniert`,
    eventId: 156014,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156014_name:Termin wurde von Teilnehmer storniert,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156013_name:Termin beginnt / hat begonnen`,
    eventId: 156013,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156013_name:Termin beginnt / hat begonnen,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156016_name:Einladungsstatus f\u00FCr Teilnehmer auf akzeptiert gesetzt`,
    eventId: 156016,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156016_name:Einladungsstatus f\u00FCr Teilnehmer auf akzeptiert gesetzt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156015_name:Termin wurde ge\u00E4ndert`,
    eventId: 156015,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156015_name:Termin ge\u00E4ndert,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156018_name:Einladungsstatus f\u00FCr Teilnehmer auf abgesagt gesetzt`,
    eventId: 156018,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156018_name:Einladungsstatus f\u00FCr Teilnehmer auf abgesagt gesetzt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156017_name:Einladungsstatus f\u00FCr Teilnehmer auf abgelehnt gesetzt`,
    eventId: 156017,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156017_name:Einladungsstatus f\u00FCr Teilnehmer auf abgelehnt gesetzt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156010_name:Keine offenen Vorbedingungen`,
    eventId: 156010,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156010_name:Termin hat keine weiteren, offenen Vorbedingungen in einem Curriculum,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156009_name:Termin abgesagt`,
    eventId: 156009,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156009_name:Termin wurde abgesagt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156008_name:Termin verschoben`,
    eventId: 156008,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156008_name:Termin wurde verschoben,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156022_name:Nutzer von Termin entfernt`,
    eventId: 156022,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156022_name:Nutzer von Termin entfernt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156021_name:Nutzer zu Termin zugeordnet`,
    eventId: 156021,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156021_name:Nutzer zu Termin zugeordnet,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156020_name:Einladungsstatus f\u00FCr Teilnehmer auf erinnert gesetzt`,
    eventId: 156020,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156020_name:Einladungsstatus f\u00FCr Teilnehmer auf erinnert gesetzt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156019_name:Einladungsstatus f\u00FCr Teilnehmer auf eingeladen gesetzt`,
    eventId: 156019,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156019_name:Einladungsstatus f\u00FCr Teilnehmer auf eingeladen gesetzt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156001_name:Veranstaltung zugewiesen`,
    eventId: 156001,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156001_name:Veranstaltung wurde zugewiesen,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156003_name:Veranstaltung wurde ausgew\u00E4hlt`,
    eventId: 156003,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156003_name:Veranstaltung wurde ausgew\u00E4hlt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156002_name:Veranstaltung entzogen`,
    eventId: 156002,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156002_name:Veranstaltung wurde entzogen,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156005_name:Terminwunsch abgelehnt`,
    eventId: 156005,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156005_name:Terminwunsch wurde abgelehnt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156004_name:Terminwunsch ausgew\u00E4hlt`,
    eventId: 156004,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156004_name:Terminwunsch wurde ausgew\u00E4hlt,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156007_name:An Termin teilgenommen`,
    eventId: 156007,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156007_name:An Termin teilgenommen,`
  },*/
  /*{
    name: $localize`:@@ehs.156.156006_name:Terminwunsch best\u00E4tigt`,
    eventId: 156006,
    moduleId: 156,
    tooltip: $localize`:@@ehs.156.156006_name:Terminwunsch wurde best\u00E4tigt,`
  },*/
  ],
}, {
  name: $localize`:@@offline_cnt_notifications_group_participation:Participation`,
  notifications: [ {
    name: $localize`:@@offline_cnt_notifications_participated_in_event:Participated in event`,
    tooltip: $localize`:@@offline_cnt_notifications_participated_in_event_tooltip:
    The notification will be sent automatically, when a user participated in an event.
    `,
    moduleId: 156,
    eventId: 156007,
  }, {
    name: $localize`:@@offline_cnt_notifications_participated_in_event_and_passed_test:Participated in event and passed test`,
    tooltip: $localize`:@@offline_cnt_notifications_participated_in_event_and_passed_test_tooltip:
    The notification will be sent automatically, when a user participated in an event, and passed a final quiz.
    `,
    moduleId: 156,
    eventId: 156012,
  } ],
}, {
  name: $localize`:@@offline_cnt_notifications_group_planning:Planning`,
  notifications: [ {
    name: $localize`:@@offline_cnt_notifications_event_cancelled:Event cancelled`,
    tooltip: $localize`:@@offline_cnt_notifications_event_cancelled_tooltip:
    The notification will be sent, when an administrator cancels an event.
    `,
    moduleId: 156,
    eventId: 156009,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_changed:Event was changed`,
    tooltip: $localize`:@@offline_cnt_notifications_event_changed_tooltip:
    The notification will be sent, when an administrator changes any settings for an event.
    `,
    moduleId: 156,
    eventId: 156015,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_unbooked:Event booking cancelled`,
    tooltip: $localize`:@@offline_cnt_notifications_event_unbooked_tooltip:
    The notification will be sent, when a user cancels their booking for an event.
    `,
    moduleId: 156,
    eventId: 156014,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_schedule_begins:Event schedule begins / has begun`,
    tooltip: $localize`:@@offline_cnt_notifications_event_schedule_begins_tooltip:
    The notification will be sent, when an event is scheduled to begin.
    You can add a negative offset to create an automatic reminder.
    `,
    moduleId: 156,
    eventId: 156013,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_delayed:Event delayed`,
    tooltip: $localize`:@@offline_cnt_notifications_event_delayed_tooltip:
    The notification will be sent, when an administrator changes the scheduled date of an event.
    `,
    moduleId: 156,
    eventId: 156008,
  }, {
    name: $localize`:@@offline_cnt_notifications_invitation_status_declined:Invitation status for
     participants set to declined`,
    tooltip: $localize`:@@offline_cnt_notifications_invitation_status_declined_tooltip:
    The notification will be sent, when an administrator declines a user's booking.
    `,
    moduleId: 156,
    eventId: 156017,
  }, {
    name: $localize`:@@offline_cnt_notifications_invitation_status_canceled:Invitation status for
     participants set to canceled`,
    tooltip: $localize`:@@offline_cnt_notifications_invitation_status_canceled_tooltip:
    The notification will be sent, when an administrator cancels a user's booking.
    `,
    moduleId: 156,
    eventId: 156018,
  }, {
    name: $localize`:@@offline_cnt_notifications_invitation_status_accepted:Invitation status for
     participants set to accepted`,
    tooltip: $localize`:@@offline_cnt_notifications_invitation_status_accepted_tooltip:
    The notification will be sent, when an administrator accepts a user's booking.
    `,
    moduleId: 156,
    eventId: 156016,
  }, {
    name: $localize`:@@offline_cnt_notifications_invitation_status_invited:Invitation status for
     participants set to invited`,
    tooltip: $localize`:@@offline_cnt_notifications_invitation_status_invited_tooltip:
    The notification will be sent, when an administrator sets a user's booking to invited.
    `,
    moduleId: 156,
    eventId: 156019,
  }, {
    name: $localize`:@@offline_cnt_notifications_invitation_status_reminded:Invitation status for
     participants set to reminded`,
    tooltip: $localize`:@@offline_cnt_notifications_invitation_status_reminded_tooltip:
    The notification will be sent, when an administrator sets a user's booking to reminded.
    `,
    moduleId: 156,
    eventId: 156020,
  } ],
}, {
  name: $localize`:@@offline_cnt_notifications_group_assignment:Assignment`,
  notifications: [ {
    name: $localize`:@@offline_cnt_notifications_event_unassigned:Event unassigned`,
    tooltip: $localize`:@@offline_cnt_notifications_event_unassigned_tooltip:
    The notification will be sent, when a user loses their assignment to the event.
    `,
    moduleId: 156,
    eventId: 156002,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_assigned:Event assigned`,
    tooltip: $localize`:@@offline_cnt_notifications_event_assigned_tooltip:
    The notification will be sent, when a user receives an assignment to the event.
    `,
    moduleId: 156,
    eventId: 156001,
  }, {
    name: $localize`:@@offline_cnt_notifications_removed_user_schedule:User removed from schedule`,
    tooltip: $localize`:@@offline_cnt_notifications_removed_user_schedule_tooltip:
    The notification will be sent, when a user loses their assignment to a specific appointment of this event.
    `,
    moduleId: 156,
    eventId: 156022,
  }, {
    name: $localize`:@@offline_cnt_notifications_assigned_user_schedule:User assigned to schedule`,
    tooltip: $localize`:@@offline_cnt_notifications_assigned_user_schedule_tooltip:
    The notification will be sent, when a user receives an assignment to a specific appointment of this event.
    `,
    moduleId: 156,
    eventId: 156021,
  } ],
}, {
  name: $localize`:@@offline_cnt_notifications_group_requests:Requests`,
  notifications: [ {
    name: $localize`:@@offline_cnt_notifications_event_was_chosen:Event was chosen`,
    tooltip: $localize`:@@offline_cnt_notifications_event_was_chosen_tooltip:
    The notification will be sent, when a user selects one of the available appointments of this event.
    `,
    moduleId: 156,
    eventId: 156003,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_requested:Event requested`,
    tooltip: $localize`:@@offline_cnt_notifications_event_requested_tooltip:
    The notification will be sent, when a user requests one of the available appointments of this event.
    `,
    moduleId: 156,
    eventId: 156004,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_request_declined:Event request declined`,
    tooltip: $localize`:@@offline_cnt_notifications_event_request_declined_tooltip:
    This notification will be sent, when an administrator declines an request to an event.
    `,
    moduleId: 156,
    eventId: 156005,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_request_acknowledged:Event request acknowledged`,
    tooltip: $localize`:@@offline_cnt_notifications_event_request_acknowledged_tooltip:
    This notification will be sent, when an administrator acknowledges an request to an event.
    `,
    moduleId: 156,
    eventId: 156006,
  } ],
}, {
  name: $localize`:@@offline_cnt_notifications_group_prerequisites:Prerequisites`,
  notifications: [ {
    name: $localize`:@@offline_cnt_notifications_no_prerequisites:Event has no prerequisites left`,
    tooltip: $localize`:@@offline_cnt_notifications_group_prerequisites_tooltip:
    The notification will be sent, when a user has no further steps to do before the event is available.
    This only concerns indirect assignments as part of a curriculum.
    `,
    moduleId: 156,
    eventId: 156010,
  }, {
    name: $localize`:@@offline_cnt_notifications_event_has_prerequisites_left:Event has prerequisites left`,
    tooltip: $localize`:@@offline_cnt_notifications_event_has_prerequisites_left_tooltip:
    The notification will be sent, when a user has further steps to do before the event is available.
    This only concerns indirect assignments as part of a curriculum.
    `,
    moduleId: 156,
    eventId: 156011,
  } ],
}, {
  name: $localize`:@@admin_lu_header:Learning content`,
  notifications: [ /*{
    name: null,
    eventId: null,
    moduleId: null,
    tooltip: null,
  }*/
  /*{
    name: $localize`:@@ehs.11.110010_name:Course assigned`,
    eventId: 110010,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110010_name:Course assigned`
  },*/
  /*{
    name: $localize`:@@ehs.11.110014_name:Course passed`,
    eventId: 110014,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110014_name:Course passed`
  },*/
  /*{
    name: $localize`:@@ehs.11.110013_name:Course canceled`,
    eventId: 110013,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110013_name:Course canceled`
  },*/
  /*{
    name: $localize`:@@ehs.11.110012_name:Course started`,
    eventId: 110012,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110012_name:Course started`
  },*/
  {
    name: $localize`:@@ehs.11.110011_name:Course unassigned`,
    eventId: 110011,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110011_name:Course unassigned`
  },
  /*{
    name: $localize`:@@ehs.11.110018_name:Kurs angeschaut`,
    eventId: 110018,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110018_name:Kurs wurde angeschaut.,`
  },*/
  /*{
    name: $localize`:@@ehs.11.110017_name:Course incomplete`,
    eventId: 110017,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110017_name:Course incomplete`
  },*/
  /*{
    name: $localize`:@@ehs.11.110016_name:Course failed`,
    eventId: 110016,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110016_name:Course failed`
  },*/
  /*{
    name: $localize`:@@ehs.11.110015_name:Course completed`,
    eventId: 110015,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110015_name:Course completed`
  },*/
  /*{
    name: $localize`:@@ehs.11.110019_name:Kurs nicht angefangen`,
    eventId: 110019,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110019_name:Kurs wurde nicht angefangen.,`
  },*/
  /*{
    name: $localize`:@@ehs.11.110009_name:Course status changed`,
    eventId: 110009,
    moduleId: 11,
    tooltip: $localize`:@@ehs.11.110009_name:Course status changed`
  },*/
  {
    name: $localize`:@@ehs.111.111009_name:Item gel\u00F6scht`,
    eventId: 111009,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111009_tooltip:Item wurde gel\u00F6scht,`
  },
  {
    name: $localize`:@@ehs.111.111008_name:Item nicht angefangen`,
    eventId: 111008,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111008_tooltip:Item wurde nicht angefangen,`
  },
  {
    name: $localize`:@@ehs.111.111007_name:Item angeschaut`,
    eventId: 111007,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111007_tooltip:Item wurde angeschaut,`
  },
  {
    name: $localize`:@@ehs.111.111006_name:Item nicht abgeschlossen`,
    eventId: 111006,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111006_tooltip:Item wurde nicht abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.111.111005_name:SCO failed`,
    eventId: 111005,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111005_name:SCO failed`
  },
  {
    name: $localize`:@@ehs.111.111004_name:SCO completed`,
    eventId: 111004,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111004_name:SCO completed`
  },
  {
    name: $localize`:@@ehs.111.111003_name:SCO passed`,
    eventId: 111003,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111003_name:SCO passed`
  },
  /*{
    name: $localize`:@@ehs.111.111002_name:Item canceled`,
    eventId: 111002,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111002_name:Item canceled`
  },*/
  {
    name: $localize`:@@ehs.111.111001_name:SCO started`,
    eventId: 111001,
    moduleId: 111,
    tooltip: $localize`:@@ehs.111.111001_name:SCO started`
  },
  /*{
    name: $localize`:@@ehs.112.112001_name:Learnprogram status changed`,
    eventId: 112001,
    moduleId: 112,
    tooltip: $localize`:@@ehs.112.112001_name:Learnprogram status changed`
  },*/
  {
    // Core.CourseType.Learning (and others)
    name: $localize`:@@ehs.112.112003_name:Learning content unassigned`,
    nameOverride: {
      // Core.CourseType.Recording
      '16': $localize`:@@ehs.112.112003_name_recording:Recording unassigned`,
    },
    eventId: 112003,
    moduleId: 112,
  },
  {
    // Core.CourseType.Learning (and others)
    name: $localize`:@@ehs.112.112002_name:Learning content assigned`,
    nameOverride: {
      // Core.CourseType.Recording
      '16': $localize`:@@ehs.112.112002_name_recording:Recording assigned`,
    },
    eventId: 112002,
    moduleId: 112,
  },
  /*{
    name: $localize`:@@ehs.112.112005_name:Learnprogram canceled`,
    eventId: 112005,
    moduleId: 112,
    tooltip: $localize`:@@ehs.112.112005_name:Learnprogram canceled`
  },*/
  {
    // Core.CourseType.Learning (and others)
    name: $localize`:@@ehs.112.112004_name:Learning content started`,
    nameOverride: {
      // Core.CourseType.Recording
      '16': $localize`:@@ehs.112.112004_name_recording:Recording started`,
    },
    eventId: 112004,
    moduleId: 112,
  },
  /*{
    name: $localize`:@@ehs.112.112007_name:Lernprogramm nicht abgeschlossen`,
    eventId: 112007,
    moduleId: 112,
    tooltip: $localize`:@@ehs.112.112007_name:Lernprogramm wurde nicht abgeschlossen,`
  },*/
  {
    // Core.CourseType.Learning (and others)
    name: $localize`:@@ehs.112.112006_name:Learning content completed`,
    nameOverride: {
      // Core.CourseType.Recording
      '16': $localize`:@@ehs.112.112006_name_recording:Recording completed`,
    },
    eventId: 112006,
    moduleId: 112,
  },
  /*{
    name: $localize`:@@ehs.112.112009_name:Lernprogramm nicht angefangen`,
    eventId: 112009,
    moduleId: 112,
    tooltip: $localize`:@@ehs.112.112009_name:Lernprogramm wurde nicht angefangen,`
  },
  {
    name: $localize`:@@ehs.112.112008_name:Lernprogramm angeschaut`,
    eventId: 112008,
    moduleId: 112,
    tooltip: $localize`:@@ehs.112.112008_name:Lernprogramm wurde angeschaut,`
  },*/
  /*{
    name: $localize`:@@ehs.113.113011_name:Test nicht angefangen`,
    eventId: 113011,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113011_name:Test wurde nicht angefangen,`
  },
  {
    name: $localize`:@@ehs.113.113010_name:Test angeschaut`,
    eventId: 113010,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113010_name:Test wurde angeschaut,`
  },*/
  {
    name: $localize`:@@ehs.113.113004_name:Test started`,
    eventId: 113004,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113004_name:Test started`
  },
  /*{
    name: $localize`:@@ehs.113.113005_name:Test canceled`,
    eventId: 113005,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113005_name:Test canceled`
  },*/
  {
    name: $localize`:@@ehs.113.113002_name:Test assigned`,
    eventId: 113002,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113002_name:Test assigned`
  },
  {
    name: $localize`:@@ehs.113.113003_name:Test unassigned`,
    eventId: 113003,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113003_name:Test unassigned`
  },
  {
    name: $localize`:@@ehs.113.113008_name:Test failed`,
    eventId: 113008,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113008_name:Test failed`
  },
  /*{
    name: $localize`:@@ehs.113.113009_name:Test nicht abgeschlossen`,
    eventId: 113009,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113009_name:Test wurde nicht abgeschlossen,`
  },*/
  {
    name: $localize`:@@ehs.113.113006_name:Test passed`,
    eventId: 113006,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113006_name:Test passed`
  },
  {
    name: $localize`:@@ehs.113.113007_name:Test completed`,
    eventId: 113007,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113007_name:Test completed`
  },
  /*{
    name: $localize`:@@ehs.113.113001_name:Test status changed`,
    eventId: 113001,
    moduleId: 113,
    tooltip: $localize`:@@ehs.113.113001_name:Test status changed`
  },*/
  /*{
    name: $localize`:@@ehs.114.114011_name:Zertifizierung nicht angefangen`,
    eventId: 114011,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114011_name:Zertifizierung wurde nicht angefangen,`
  },
  {
    name: $localize`:@@ehs.114.114010_name:Zertifizierung angeschaut`,
    eventId: 114010,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114010_name:Zertifizierung wurde angeschaut,`
  },
  {
    name: $localize`:@@ehs.114.114008_name:Zertifizierung durchgefallen`,
    eventId: 114008,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114008_name:Im Zertifizierung durchgefallen,`
  },
  {
    name: $localize`:@@ehs.114.114009_name:Zertifizierung nicht abgeschlossen`,
    eventId: 114009,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114009_name:Zertifizierung wurde nicht abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.114.114004_name:Zertifizierung gestartet`,
    eventId: 114004,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114004_name:Zertifizierung wurde gestartet,`
  },
  {
    name: $localize`:@@ehs.114.114005_name:Zertifizierung abgebrochen`,
    eventId: 114005,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114005_name:Zertifizierung wurde abgebrochen,`
  },
  {
    name: $localize`:@@ehs.114.114006_name:Zertifizierung bestanden`,
    eventId: 114006,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114006_name:Zertifizierung wurde bestanden,`
  },
  {
    name: $localize`:@@ehs.114.114007_name:Zertifizierung abgeschlossen`,
    eventId: 114007,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114007_name:Zertifizierung wurde abgeschlossen,`
  },
  {
    name: $localize`:@@ehs.114.114001_name:Zertifizierung Status ge\u00E4ndert`,
    eventId: 114001,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114001_name:Zertifizierung Status wurde ge\u00E4ndert,`
  },
  {
    name: $localize`:@@ehs.114.114002_name:Zertifizierung zugewiesen`,
    eventId: 114002,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114002_name:Zertifizierung wurde zugewiesen,`
  },
  {
    name: $localize`:@@ehs.114.114003_name:Zertifizierung entzogen`,
    eventId: 114003,
    moduleId: 114,
    tooltip: $localize`:@@ehs.114.114003_name:Zertifizierung wurde entzogen,`
  },*/
  /*{
    name: $localize`:@@ehs.115.115008_name:Dokument angeschaut`,
    eventId: 115008,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115008_name:Dokument wurde angeschaut,`
  },*/
  {
    name: $localize`:@@ehs.115.115009_name:Dokument nicht angefangen`,
    eventId: 115009,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115009_tooltip:Dokument wurde nicht angefangen,`
  },
  {
    name: $localize`:@@ehs.115.115006_name:Document completed`,
    eventId: 115006,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115006_name:Document completed`
  },
  /*{
    name: $localize`:@@ehs.115.115007_name:Dokument nicht abgeschlossen`,
    eventId: 115007,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115007_name:Dokument wurde nicht abgeschlossen,`
  },*/
  {
    name: $localize`:@@ehs.115.115004_name:Document started`,
    eventId: 115004,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115004_name:Document started`
  },
  /*{
    name: $localize`:@@ehs.115.115005_name:Document canceled`,
    eventId: 115005,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115005_name:Document canceled`
  },*/
  {
    name: $localize`:@@ehs.115.115002_name:Document assigned`,
    eventId: 115002,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115002_name:Document assigned`
  },
  {
    name: $localize`:@@ehs.115.115003_name:Document unassigned`,
    eventId: 115003,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115003_name:Document unassigned`
  },
  /*{
    name: $localize`:@@ehs.115.115001_name:Document status changed`,
    eventId: 115001,
    moduleId: 115,
    tooltip: $localize`:@@ehs.115.115001_name:Document status changed`
  },*/
  ],
}, {
  name: $localize`:@@global_user:User`,
  notifications: [ /*{
    name: null,
    eventId: null,
    moduleId: null,
    tooltip: null,
  },*/
  {
    name: $localize`:@@ehs.10.100004_name:User login`,
    eventId: 100004,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100004_name:User login`
  },
  {
    name: $localize`:@@ehs.10.100005_name:Self registered user registered`,
    eventId: 100005,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100005_name:Self registered user registered`
  },
  {
    name: $localize`:@@ehs.10.100006_name:User self registration expired`,
    eventId: 100006,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100006_name:User self registration expired`
  },
  {
    name: $localize`:@@ehs.10.100007_name:Self registered user removed`,
    eventId: 100007,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100007_name:Self registered user removed`
  },
  {
    name: $localize`:@@ehs.10.100000_name:User created`,
    eventId: 100000,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100000_name:User created`
  },
  {
    name: $localize`:@@ehs.10.100001_name:User updated`,
    eventId: 100001,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100001_name:User updated`
  },
  {
    name: $localize`:@@ehs.10.100002_name:User removed`,
    eventId: 100002,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100002_name:User removed`
  },
  {
    name: $localize`:@@ehs.10.100003_name:User first login`,
    eventId: 100003,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100003_name:User first login`
  },
  {
    name: $localize`:@@ehs.10.100008_name:Self registered user first login`,
    eventId: 100008,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100008_name:Self registered user first login`
  },
  {
    name: $localize`:@@ehs.10.100009_name:Self registered user login`,
    eventId: 100009,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100009_name:Self registered user login`
  },
  {
    name: $localize`:@@ehs.10.100011_name:Booking process finished`,
    eventId: 100011,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100011_name:Booking process finished`
  },
  {
    name: $localize`:@@ehs.10.100012_name:Unbooking finished`,
    eventId: 100012,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100012_name:Unbooking finished`
  },
  {
    name: $localize`:@@ehs.10.100010_name:User activated`,
    eventId: 100010,
    moduleId: 10,
    tooltip: $localize`:@@ehs.10.100010_name:User activated`
  },
  /*{
    moduleId: 10,
    eventId: 100000,
    name: 'Benutzer erstellt',
    tooltip: 'Benutzer erstellt',
  },
  {
    moduleId: 10,
    eventId: 100001,
    name: 'Benutzer bearbeitet',
    tooltip: 'Benutzer bearbeitet',
  },
  {
    moduleId: 10,
    eventId: 100002,
    name: 'Benutzer gel\u00F6scht',
    tooltip: 'Benutzer gel\u00F6scht',
  },
  {
    moduleId: 10,
    eventId: 100003,
    name: 'Erster Login',
    tooltip: 'Erster Login',
  },
  {
    moduleId: 10,
    eventId: 100004,
    name: 'Benutzer Login',
    tooltip: 'Benutzer Login',
  },
  {
    moduleId: 10,
    eventId: 100005,
    name: 'Selbstreg. Benutzer registriert',
    tooltip: 'Selbstreg. Benutzer wurde registriert',
  },
  {
    moduleId: 10,
    eventId: 100006,
    name: 'Selbstreg. Benutzer Konto abgelaufen',
    tooltip: 'Selbstreg. Benutzer Konto ist abgelaufen.',
  },
  {
    moduleId: 10,
    eventId: 100007,
    name: 'Selbstreg. Benutzer Konto gel\u00F6scht',
    tooltip: 'Selbstsreg. Nuzter gel\u00F6scht wurde',
  },
  {
    moduleId: 10,
    eventId: 100008,
    name: 'Selbstreg. Benutzer erster Login',
    tooltip: 'Selbstreg. Benutzer erster Login',
  },
  {
    moduleId: 10,
    eventId: 100009,
    name: 'Selbstreg. Benutzer Login',
    tooltip: 'Selbstreg. Benutzer Login'
  },
  {
    moduleId: 10,
    eventId: 100010,
    name: 'Benutzerkonto aktiviert',
    tooltip: 'Benutzerkonto aktiviert'
  },
  {
    moduleId: 10,
    eventId: 100011,
    name: 'Buchungsvorgang abgeschlossen',
    tooltip: 'Buchungsvorgang abgeschlossen'
  },
  {
    moduleId: 10,
    eventId: 100012,
    name: 'Stornierung abgeschlossen',
    tooltip: 'Stornierung abgeschlossen'
  },*/
  ],
}, {
  name: $localize`:@@admin_content_content_curriculum:Curriculum`,
  notifications: [ /*{
    name: null,
    eventId: null,
    moduleId: null,
    tooltip: null,
  }*/

  {
    name: $localize`:@@ehs.141.141009_name:CurriculumItem done (status: passed, completed, certified)`,
    eventId: 141009,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141009_name:CurriculumItem done (status: passed, completed, certified)`
  },
  {
    name: $localize`:@@ehs.141.141008_name:CurInCurItem certified`,
    eventId: 141008,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141008_name:CurInCurItem certified`
  },
  {
    name: $localize`:@@ehs.141.141007_name:No tries left`,
    eventId: 141007,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141007_name:No tries left`
  },
  {
    name: $localize`:@@ehs.141.141006_name:CurriculumItem is executable first time`,
    eventId: 141006,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141006_name:CurriculumItem is executable first time`
  },
  /*{
    name: $localize`:@@ehs.141.141005_name:CurriculumItem nicht gestartet`,
    eventId: 141005,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141005_name:CurriculumItem wurde nicht gestartet,`
  },*/
  {
    name: $localize`:@@ehs.141.141004_name:CurriculumItem started`,
    eventId: 141004,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141004_name:CurriculumItem started`
  },
  {
    name: $localize`:@@ehs.141.141003_name:CurriculumItem failed`,
    eventId: 141003,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141003_name:CurriculumItem failed`
  },
  {
    name: $localize`:@@ehs.141.141002_name:CurriculumItem passed`,
    eventId: 141002,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141002_name:CurriculumItem passed`
  },
  {
    name: $localize`:@@ehs.141.141001_name:CurriculumItem completed`,
    eventId: 141001,
    moduleId: 141,
    tooltip: $localize`:@@ehs.141.141001_name:CurriculumItem completed`
  },
  {
    name: $localize`:@@ehs.14.140001_name:Curriculum assigned`,
    eventId: 140001,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140001_name:Curriculum assigned`
  },
  {
    name: $localize`:@@ehs.14.140003_name:Curriculum archived`,
    eventId: 140003,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140003_name:Curriculum archived`
  },
  {
    name: $localize`:@@ehs.14.140002_name:Curriculum unassigned`,
    eventId: 140002,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140002_name:Curriculum unassigned`
  },
  {
    name: $localize`:@@ehs.14.140005_name:Curriculum ends`,
    eventId: 140005,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140005_name:Curriculum ends`
  },
  {
    name: $localize`:@@ehs.14.140004_name:Curriculum started`,
    eventId: 140004,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140004_name:Curriculum started`
  },
  {
    name: $localize`:@@ehs.14.140007_name:Curriculum execution time span ended`,
    eventId: 140007,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140007_name:Curriculum execution time span ended`
  },
  {
    name: $localize`:@@ehs.14.140006_name:Curriculum completed`,
    eventId: 140006,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140006_name:Curriculum completed`
  },
  {
    name: $localize`:@@ehs.14.140009_name:Curriculum validity interval ended`,
    eventId: 140009,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140009_name:Curriculum validity interval ended`
  },
  {
    name: $localize`:@@ehs.14.140008_name:Curriculum validity interval startet`,
    eventId: 140008,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140008_name:Curriculum validity interval startet`
  },
  {
    name: $localize`:@@ehs.14.140012_name:Reiteration ended`,
    eventId: 140012,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140012_name:Reiteration ended`
  },
  {
    name: $localize`:@@ehs.14.140011_name:Reiteration startet`,
    eventId: 140011,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140011_name:Reiteration startet`
  },
  {
    name: $localize`:@@ehs.14.140013_name:Iteration reset`,
    eventId: 140013,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140013_name:Iteration reset`
  },
  {
    name: $localize`:@@ehs.14.140016_name:New items first available`,
    eventId: 140016,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140016_name:New items first available`
  },
  {
    name: $localize`:@@ehs.14.140015_name:Validity ended`,
    eventId: 140015,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140015_name:Validity ended`
  },
  {
    name: $localize`:@@ehs.14.140019_name:Curriculum validity interval set`,
    eventId: 140019,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140019_name:Curriculum validity interval set`
  },
  {
    name: $localize`:@@ehs.14.140020_name:Started to work on`,
    eventId: 140020,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140020_name:Started to work on`
  },
  {
    name: $localize`:@@ehs.14.140010_name:New item unlocked`,
    eventId: 140010,
    moduleId: 14,
    tooltip: $localize`:@@ehs.14.140010_name:New item unlocked`
  },
  ],
} ];

export const CONTENT_NOTIFICATIONS_TEXTS = CONTENT_NOTIFICATIONS_TYPES
  .flatMap(o => o.notifications)
  .reduce((pV, notification) => {
    (pV[notification.moduleId] ??= []).push(notification);
    return pV;
  }, <NumberedAnyObject<NotificationAddType[]>>{});

export interface NotificationPageData
  extends TransientView<NotificationView> {
  id: number;
  title: Translation;
  description: Translation;
  targetObjectId: string;
  eventId: number;
  eventModuleId: number;
  actionType: number;
  from: string;
  bcc: string;
  cc: string;
  to: string;
  body?: string;
  bodyEN?: string;
  bodyAR?: string;
  subject: string;
  subjectEN: null;
  subjectAR: null;
  enabled: boolean;
  enabledStartDate?: number;
  enabledEndDate?: number;
  targetGroupIds?: string;
  distTypeSelect: Core.DistAssignmentType;
  excludedTgs: string;
  defaultTemplate: boolean;
  offset: string;
  hasInterval: boolean;
  intervalSpan?: string;
  signatureId: string;
  defaultLang: string;
  attachments: Array<FileInfo>;
  parentTargetObjectId?: string;
  contextPattern: string;
}

export interface NotificationTemplateDetails {
  messageBodyEN: string;
  messageBody: string;
  subject: string;
  description: string;
  from: string;
  title: string;
  subjectEN: string;
  key: string;
  cc: string;
  to: string;
  bcc: string;
}

export interface NotificationTemplate {
  title: string;
  key: string;
}

export interface NotificationDetailsContentResponse {
  notificationData: NotificationPageData;
  notificationTemplates: NotificationTemplate[];
}

export interface NotificationView {
  showTargetGroups: Array<Principal>;
  hideTargetGroups: Array<Principal>;
  allTargetGroups: Map<number, TargetGroup> | Observable<Map<number, TargetGroup>>;
  senders: Array<Principal>;
  receivers: Array<MessageAccountInfo>;
  macrosSettings: MacroContextSettings;
  signatures: Signature[];
  courses: NamedDistributable[],
  items: NotificationIdentifiable[],
  subTargets: SubTargetOptions[],
}

