import { LayoutModule } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, Inject, LOCALE_ID, NgModule, SecurityContext} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { map, take } from 'rxjs/operators';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { State } from './app.state';
import { CoreModule } from './core/core.module';
import { InfoModule } from './core/info/info.module';
import { AccountInterceptor } from './core/interceptors/account.interceptor';
import { ErrorsInterceptorService } from './core/interceptors/errors-interceptor.service';
import { JsonEncodingInterceptor } from './core/interceptors/json-encoding.interceptor';
import { LoadingInterceptorService } from './core/interceptors/loading-interceptor.service';
import { MaintenanceModule } from './core/maintenance/maintenance.module';
import { OwnMatPaginatorIntl } from './core/paginator-de';
import { PreloadService, preloadServiceFactory } from './core/preload.service';
import { RagIconModule } from './core/rag-icon-module';
import { ThemeService } from './core/theme.service';
import { RagLayoutModule } from './rag-layout/rag-layout.module';
import { AccountDesignModule } from './route/admin/account-design/account-design.module';
import { AdminModule } from './route/admin/admin.module';
import { ReportGenAdminModule } from './route/admin/report-gen-admin/report-gen-admin.module';
import { CtrlModule } from './route/ctrl/ctrl.module';
import { ContactComponent } from './route/main/contact/contact.component';
import { NotFound404Module } from './route/main/not-found-404/not-found-404.module';
import { PasswordForgottenComponent } from './route/main/password-forgotten/password-forgotten.component';
import { PasswordUpdateComponent } from './route/main/password-update/password-update.component';
import { SelfRegisterModule } from './route/main/self-register/self-register.module';
import { TechCheckComponent } from './route/main/tech-check/tech-check.component';
import { CertificatesModule } from './route/user/certificates/certificates.module';
import { ContentOverviewModule } from './route/user/content-overview/content-overview.module';
import { LinkRoutingModule } from './route/user/link/link.routing';
import { MailModule } from './route/user/mail/mail.module';
import { ScoRunningObserverService } from './route/user/sco/sco-running-observer.service';
import { UserModule } from './route/user/user.module';
import { PostLoginModule } from './route/main/post-login/post-login.module';
import { RuntimeEnvironmentService } from './core/runtime-environment.service';
import { LoginFormModule } from './component/login/login-form/login-form.module';
import { LoginRedirectModule } from './route/main/login/login-redirect/login-redirect.module';
import { CurrencyPipe } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';
import { MaintenanceService } from './core/maintenance/maintenance.service';
import { ChangeNotesModule } from './route/main/change-notes/change-notes.module';
import { LearnerCourseTodoModule } from './route/user/content/learner-course/learner-course-todo/learner-course-todo.module';
import { HeaderSidenavModule } from './component/header/header-sidenav/header-sidenav.module';
import { SelfRegisterBridgeModule } from './route/main/self-register/self-register-bridge/self-register-bridge.module';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { PasswordValidationComponent } from './component/password-validation/password-validation.component';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderModule } from './component/header/header.module';
import { CourseCardModule } from './component/card/course/course-card.module';
import {CurriculumCardModule} from './component/card/curriculum/curriculum-card.module';
import {EventCardModule} from './component/card/event/event-card.module';
import {GrafanaFaroService} from './core/grafana-faro/grafana-faro.service';
import {GlobalErrorHandler} from './core/grafana-faro/global-error-handler';
import {HeaderLogoComponent} from './component/header/header-logo/header-logo.component';
import {MatMenuModule} from '@angular/material/menu';
import {LoginFormV2Module} from './component/login/login-form-v2/login-form-v2.module';
import { BetaToggleComponent } from './component/beta-toggle/beta-toggle.component';
import {AppContentComponent} from './component/app-content/app-content.component';
import {HeaderV2Component} from './component/header/header-v2/header-v2.component';
import { TimePickerModule } from './component/input/time-picker/time-picker.module';


@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    PasswordForgottenComponent,
    TechCheckComponent,
    PasswordUpdateComponent,
  ],
  imports: [
    // do not move the next lines
    MailModule,
    CtrlModule,
    ContentOverviewModule,
    CertificatesModule,
    LinkRoutingModule,
    LoginRedirectModule,
    SelfRegisterModule,
    SelfRegisterBridgeModule,
    UserModule,
    AdminModule,
    FormsModule,
    HeaderModule,
    ReactiveFormsModule,
    //
    AccountDesignModule,
    AngularSvgIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChangeNotesModule,
    PasswordValidationComponent,
    FooterComponent,
    PageHeaderComponent,
    CoreModule,
    FormsModule,
    HttpClientModule,
    InfoModule,
    LayoutModule,
    MaintenanceModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatListModule,
    MatInputModule,
    MatOptionModule,
    MatMomentDateModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    NotFound404Module,
    RagIconModule,
    RagLayoutModule,
    ReactiveFormsModule,
    ReportGenAdminModule,
    PostLoginModule,
    LoginFormModule,
    LearnerCourseTodoModule,
    CourseCardModule,
    CurriculumCardModule,
    EventCardModule,
    HeaderLogoComponent,
    MarkdownModule.forRoot({
      // todo revert to default sanitizer, if markdown tag should be used for end users
      sanitize: SecurityContext.NONE,
    }),
    HeaderSidenavModule,
    MatMenuModule,
    LoginFormV2Module,
    BetaToggleComponent,
    AppContentComponent,
    HeaderV2Component,
    TimePickerModule,
  ],
  providers: [
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AccountInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonEncodingInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: OwnMatPaginatorIntl },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    {
      provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'l',
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: APP_INITIALIZER, useFactory: preloadServiceFactory, deps: [ PreloadService ], multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler,arguments: [GrafanaFaroService]},
  ],
  bootstrap: [ AppComponent ],
  exports: [],
})

export class AppModule {
  constructor(
    domSanitizer: DomSanitizer,
    @Inject(LOCALE_ID) locale: string,
    maintenanceService: MaintenanceService,
    matIconRegistry: MatIconRegistry,
    preloadService: PreloadService,
    runtimeEnvironmentService: RuntimeEnvironmentService,
    themeService: ThemeService,
    scoRunningObserverService: ScoRunningObserverService,
    grafanaFaroService: GrafanaFaroService,
  ) {
    // todo check if locale is correct
    State.language = locale;

    matIconRegistry.addSvgIcon('rag-closed', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Controls/closed.svg'));
    matIconRegistry.addSvgIcon('rag-correct', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Controls/correct.svg'));
    matIconRegistry.addSvgIcon('rag-locked', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Controls/locked.svg'));
    matIconRegistry.addSvgIcon('rag-opened', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Controls/opened.svg'));
    matIconRegistry.addSvgIcon('rag-reload', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Controls/Reload.svg'));
    matIconRegistry.addSvgIcon('rag-curriculum', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Menu/Lernen.svg'));
    matIconRegistry.addSvgIcon('rag-course', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Sub/Webinar_2_small.svg'));
    matIconRegistry.addSvgIcon('rag-context', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Sub/Quiz_Freiwillig_small.svg'));
    matIconRegistry.addSvgIcon('rag-vroom', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Titel/Webinar_1.svg'));
    matIconRegistry.addSvgIcon('rag-offlineCnt', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/Titel/Praesenzveranstaltung_1.svg'));

    preloadService.getMdiSvg().subscribe(mdiSvg => {
      // adding Material Design Icons as svg from
      // https://dev.materialdesignicons.com/icons
      if ( mdiSvg ) {
        matIconRegistry.addSvgIconSetLiteral(domSanitizer.bypassSecurityTrustHtml(mdiSvg));
      } else {
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/mdi.svg'));
      }
    });

    preloadService.preloadFinished$
      .pipe(take(1))
      .pipe(map(() => {
        runtimeEnvironmentService.onInit();
        themeService.onInit();
        maintenanceService.onInit();
        grafanaFaroService.onInit();
      }))
      .subscribe();

    scoRunningObserverService.observePrincipal()
      .subscribe();
  }
}
