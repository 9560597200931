import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { merge } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subscriber } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { ApiUrls } from 'src/app/core/api.urls';
import {
  AnyObject,
  ContentReference,
  FileInfo,
  JsonOmitViewReplacer,
  NumberedAnyObject,
  TargetGroup,
} from 'src/app/core/core.types';
import { ApiResponse, HttpRequestOptions } from 'src/app/core/global.types';
import { InfoService } from 'src/app/core/info/info.service';
import { CancelButton, DeleteButton, InfoType, MessageConstants, MessageKey } from 'src/app/core/info/info.types';
import { ColumnSettings } from 'src/app/core/report/report.types';
import * as uuid from 'uuid';
import { State } from '../../../app.state';
import { CachedSubject } from '../../../core/cached-subject';
import { ModalDialog } from '../../../core/modal-dialog';
import { ObjectUsageTypes } from '../../../core/object-usage.types';
import { EventParticipantsService } from './components/content-events/event-participants/event-participants.service';
import { UsageDialogComponent, UsageDialogData } from './components/usage-dialog/usage-dialog.component';
import { Content } from '../../../core/content/content.types';
import { FileUploadService } from '../../../core/files/file-upload.service';
import { FileAuthorizationRefType } from '../../../core/files/file-upload.types';
import {
  GenericMessageDialogComponent
} from '../../../component/generic-message-dialog/generic-message-dialog.component';
import { UploadFile } from 'src/app/core/files.types';

export interface OfflineContentsResponse {
  expiredEventSchedules: Array<number>;
  locations: AnyObject<OfflineContent.Location>;
  offlineContent?: OfflineContent.Event;
  offlineContents?: Array<OfflineContent.Event>;
  providers: AnyObject<OfflineContent.Provider>;
  targetGroups?: NumberedAnyObject<TargetGroup>;
  trainers: AnyObject<OfflineContent.Trainer>;
  upcomingEventSchedules: Array<number>;
}

const userTimeZone = State.timeZone;

@Injectable({
  providedIn: 'root',
})
export class AdminOfflineService {

  componentChangeEvent$: Observable<OfflineContent.ComponentChangeEvent>;
  componentInvalidatedEvent$: Observable<boolean>;
  currentOfflineEvent: OfflineContent.EventSchedule;
  eventsChangeEvent$: Observable<OfflineContent.ChangeEvent<OfflineContent.Event>>;
  readonly offlineEventAdded$: Observable<OfflineContent.EventSchedule>;
  locations$: Observable<AnyObject<OfflineContent.Location>>;
  providers$: Observable<AnyObject<OfflineContent.Provider>>;
  trainers$: Observable<AnyObject<OfflineContent.Trainer>>;
  serviceEvent$: Observable<OfflineContent.ServiceEvent>;

  private _componentChangeEvent$ = new CachedSubject<OfflineContent.ComponentChangeEvent>(null);
  private _componentInvalidatedEvent$ = new CachedSubject<boolean>(null);
  private _eventsChangeEvent$ = new BehaviorSubject<OfflineContent.ChangeEvent<OfflineContent.Event>>(null);
  private _offlineEventAdded$ = new EventEmitter<OfflineContent.EventSchedule>();
  private _locations = new CachedSubject<AnyObject<OfflineContent.Location>>(null);
  private _providers = new CachedSubject<AnyObject<OfflineContent.Provider>>(null);
  private _trainers = new CachedSubject<AnyObject<OfflineContent.Trainer>>(null);
  private _serviceEvent$ = new CachedSubject<OfflineContent.ServiceEvent>(null);

  constructor(
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
    private eventParticipantService: EventParticipantsService,
    private fileUploadService: FileUploadService

  ) {
    this.componentChangeEvent$ = this._componentChangeEvent$.withoutEmptyValues();
    this.componentInvalidatedEvent$ = this._componentInvalidatedEvent$.withoutEmptyValues();
    this.eventsChangeEvent$ = this._eventsChangeEvent$.asObservable();
    this.offlineEventAdded$ = this._offlineEventAdded$.asObservable();
    this.locations$ = this._locations.asObservable();
    this.providers$ = this._providers.asObservable();
    this.trainers$ = this._trainers.asObservable();
    this.serviceEvent$ = this._serviceEvent$.withoutEmptyValues();
  }

  static newEventSchedule(todayAsDefault = true): OfflineContent.EventSchedule {

    const startDate = new Date();
    startDate.setHours(9, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(17, 0, 0, 0);

    return {
      $view: {
        startDate: moment(),
        endDate: moment(),
        duration: 0,
        uuid: uuid.v4(),
        expiredEvents: [],
        upcomingEvents: [],
      },
      id: 0,
      active: false,
      type: 'TrainingBlock',
      eventDate: todayAsDefault ? startDate.getTime() : null,
      eventDateUntil: todayAsDefault ? endDate.getTime() : null,
      contentId: 0,
      timeZone: userTimeZone,
      minUserCount: null,
      maxUserCount: null,
      creationDate: null,
      description: {
        de: '',
        en: '',
      },
      examination: false,
      actualUserCount: 0,
      lastModified: null,
      attachments: [],
      trainerAttachments: [],
      trainers: [],
      location: null,
      provider: null,
      netDuration: 0,
      controllingRequired: false,
      bookingUntilEnd: false,
      extLoginMinsPrefix: 15,
      extLoginMinsSuffix: 60,
    };
  }

  setLocation(location: OfflineContent.Location) {
    let locations = this._locations.value ?? {};
    if (location != null) {
      locations[location.uuid] = location;
    }
    this._locations.next(locations);
  }

  addLocation(location: OfflineContent.Location, persist = false): Observable<OfflineContent.Location> {
    return new Observable(observer => {
      if ( location.uuid == null ) {
        location.uuid = uuid.v4();
      }

      const closure = (_location: OfflineContent.Location) => {
        const newCollection = { ...this._locations.value };
        newCollection[location.uuid] = _location;

        this._locations.next(newCollection);
        observer.next(_location);
      };

      this.updateAdditionalData(persist, 'Location', 'location', location.uuid, location, closure, observer);
    });
  }

  addProvider(provider: OfflineContent.Provider, persist = false): Observable<OfflineContent.Provider> {
    return new Observable(observer => {
      if ( provider.uuid == null ) {
        provider.uuid = uuid.v4();
      }

      const closure = (_provider: OfflineContent.Provider) => {
        const newCollection = { ...this._providers.value };
        newCollection[provider.uuid] = _provider;

        this._providers.next(newCollection);
        observer.next(_provider);
      };

      this.updateAdditionalData(persist, 'Providers', 'provider', provider.uuid, provider, closure, observer);
    });
  }

  addTrainer(trainer: OfflineContent.Trainer, persist = false): Observable<OfflineContent.Trainer> {
    return new Observable<OfflineContent.Trainer>(observer => {
      if ( trainer.uuid == null ) {
        trainer.uuid = uuid.v4();
      }

      const closure = (_trainer: OfflineContent.Trainer) => {
        const newCollection = { ...this._trainers.value };
        newCollection[trainer.uuid] = _trainer;

        this._trainers.next(newCollection);
        observer.next(_trainer);
      };

      this.updateAdditionalData(persist, 'Trainer', 'trainer', trainer.uuid, trainer, closure, observer);
    })
      .pipe(switchMap(response => this.markTrainerPictureAsInUse(trainer, response)));
  }

  allLocations(): Observable<Array<OfflineContent.Location>> {
    const url = ApiUrls.getKey('Location');
    return this.http.get<ApiResponse<Array<OfflineContent.Location>>>(url).pipe(map(response => response.locations));
  }

  allProviders(): Observable<Array<OfflineContent.Provider>> {
    const url = ApiUrls.getKey('Providers');
    return this.http.get<ApiResponse<Array<OfflineContent.Provider>>>(url).pipe(map(response => response.providers));
  }

  allTrainers(): Observable<Array<OfflineContent.Trainer>> {
    const url = ApiUrls.getKey('Trainer');
    return this.http.get<ApiResponse<Array<OfflineContent.Trainer>>>(url).pipe(map(response => response.trainers));
  }

  copyContent(contentId: number, copyOptions: OfflineContent.CopyOptions): Observable<Content> {

    const url = ApiUrls.getKey('CopyOfflineContent').replace(/{offlineContentId}/, String(contentId));

    const formData = new FormData();

    if ( copyOptions != null ) {

      formData.append('copyOptions', JSON.stringify(copyOptions, JsonOmitViewReplacer));
    }

    return this.http.request<ApiResponse<Content>>(
      'POST', url, { body: formData },
    ).pipe(map(response => response.offlineContent));
  }

  copyEventSchedule(eventScheduleId: number): Observable<OfflineContent.EventSchedule> {

    const url = `${ApiUrls.getKey('OfflineContents')}/event/${eventScheduleId}/copy`;
    return this.http
      .post<ApiResponse<OfflineContent.EventSchedule>>(url, HttpRequestOptions)
      .pipe(map(response => response.offlineEvent))
      .pipe(tap(event => this._offlineEventAdded$.next(event)));
  }

  createViewDataForEventSchedule(eventSchedule: OfflineContent.EventSchedule) {
    const startDate = moment(eventSchedule.eventDate);
    const endDate = moment(eventSchedule.eventDateUntil);
    const duration = eventSchedule.netDuration != null ?
      eventSchedule.netDuration : endDate.diff(startDate, 'minute');

    eventSchedule.$view = {
      upcomingEvents: [],
      expiredEvents: [],
      startDate,
      endDate,
      duration,
      uuid: uuid.v4(),
    };
  }

  deleteEvent(eventId: number): Observable<boolean> {
    const url = ApiUrls.getKey('OfflineContent')
      .replace('{contentId}', String(eventId));
    return this.http.delete<ApiResponse<any>>(url).pipe(map(response => {
      if ( response.success ) {
        this._eventsChangeEvent$.next({
          id: eventId,
          change: 'removed',
        });
      }
      return response.error == null;
    }));
  }

  deleteEventSchedule(eventId: number, eventScheduleId: number): Observable<void> {
    return this.eventParticipantService
      // check if there are active assignments
      .loadParticipants(eventId, eventScheduleId)
      .pipe(take(1))
      .pipe(switchMap(response => {
        const hasActualUser = response?.assignedParticipants?.length > 0;
        let message: string;
        if ( hasActualUser ) {
          message = $localize`:@@delete_event_schedule_with_assignments:Participants are assigned on this schedule.<br>Do you want to remove these assignments and delete the schedule?<br>Note: Deleting the schedule may send automatic notifications`;
        } else {
          message = $localize`:@@offline_cnt_event_schedule_delete_confirm_body:Would you like to delete this schedule?`;
        }

        return this.dialog.open(GenericMessageDialogComponent, {
          data: {
          titleKey: 'general_dialog_pleaseconfirm_title',
          message,
          buttons: CancelButton | DeleteButton,
          },
          maxWidth: '56vw',
        }).afterClosed();
      }))
      .pipe(takeWhile(button => button === DeleteButton))
      // send api request
      .pipe(switchMap(() => {
        const url = ApiUrls.getKey('OfflineContentDelete')
          .replace(/{eventId}/gi, String(eventId))
          .replace(/{eventScheduleId}/gi, String(eventScheduleId));
        return this.http.delete<ApiResponse<void>>(url).pipe(map(() => void (0)));
      }))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.OFFLINE_CNT.SCHEDULE.DELETE.FAILED, InfoType.Warning);
        return EMPTY;
      }))
      .pipe(take(1))
      .pipe(tap(() => {
        this.infoService.showSnackbar(MessageKey.OFFLINE_CNT.SCHEDULE.DELETE.CONFIRM, InfoType.Success);
        this._eventsChangeEvent$.next({
          id: eventId,
          change: 'modified',
        });
      }));
  }

  deleteEventSchedules(events: OfflineContent.EventSchedule[]): Observable<void | never> {
    if ( !(events.length > 0) ) {
      return EMPTY;
    }

    const hasActualUser = events.find(event => event.actualUserCount > 0) != null;
    const multipleDelete = events.length > 1;
    let message: string;

    if ( hasActualUser ) {
      message = !multipleDelete ?
        $localize`:@@delete_event_schedule_with_assignments:Participants are assigned on this schedule.<br>Do you want to remove these assignments and delete the schedule?<br>Note: Deleting the schedule may send automatic notifications` :

        $localize`:@@delete_event_schedules_with_assignments:Participants are assigned on these schedules.<br>Do you want to remove these assignments and delete the schedules?<br>Note: Deleting the schedules may send automatic notifications.`;
    } else {
      message = !multipleDelete ?
        $localize`:@@offline_cnt_event_schedule_delete_confirm_body:Would you like to delete this schedule?` :

        $localize`:@@offline_cnt_event_schedules_delete_confirm_body:Would you like to delete these schedules?`
      ;
    }

    return this.dialog.open(GenericMessageDialogComponent, {
      data: {
        titleKey: 'general_dialog_pleaseconfirm_title',
        message,
        buttons: CancelButton | DeleteButton,
      },
      maxWidth: '56vw',
      }).afterClosed()
      .pipe(takeWhile(response => response === DeleteButton))
      .pipe(switchMap(() => {

        const deleteTasks = events
          .map(eventSchedule => {
            const url = ApiUrls.getKey('OfflineContentDelete')
              .replace(/{eventId}/gi, String(eventSchedule.contentId))
              .replace(/{eventScheduleId}/gi, String(eventSchedule.id));
            return this.http.delete<ApiResponse<void>>(url);
          });
        return forkJoin(deleteTasks);
      }))
      .pipe(catchError(() => {
        this.infoService.showMessage(MessageConstants.API.ERROR, {
          infoType: InfoType.Warning,
        });
        return EMPTY;
      }))
      .pipe(take(1))
      .pipe(tap(() => {
        let successMessage = $localize`:@@offline_cnt_event_schedule_delete_confirm:The event schedule has been deleted successfully.`;
        if (multipleDelete) {
          successMessage =
            $localize`:@@offline_cnt_event_schedules_delete_confirm:The event schedules have been deleted successfully.`;
        }
        this.infoService.showMessage(successMessage, {
          infoType: InfoType.Success
        });
      }))
      .pipe(map(() => void (0)));
  }

  deleteLocation(locationUUID: string): Observable<boolean> {
    const url = `${ApiUrls.getKey('Location')}/${locationUUID}`;
    return this.http.delete<ApiResponse<void>>(url).pipe(map(response => response.success)).pipe(catchError(error => {
      if ( error.status === 409 ) {
        this.infoService.showSnackbar(MessageKey.GENERAL_DELETE_CONFLICT, InfoType.Warning);
      }
      return error;
    }));
  }

  deleteProvider(providerUUID: string): Observable<boolean> {
    return new Observable(observer => {
      const url = `${ApiUrls.getKey('Providers')}/${providerUUID}`;
      this.http.delete<ApiResponse<void>>(url).subscribe(response => {
        observer.next(response.success);
        observer.complete();
      }, (error: HttpErrorResponse) => {
        if ( error.status === 409 ) {
          this.infoService.showSnackbar(MessageKey.GENERAL_DELETE_CONFLICT, InfoType.Warning);
          observer.next(false);
        } else {
          console.error(error);
          observer.error(error);
        }
        observer.complete();
      });
    });
  }

  deleteTrainer(trainerUUID: string): Observable<boolean> {
    return new Observable(observer => {
      const url = `${ApiUrls.getKey('Trainer')}/${trainerUUID}`;
      this.http.delete<ApiResponse<void>>(url).subscribe(response => {
        observer.next(response.success);
        observer.complete();
      }, (error: HttpErrorResponse) => {
        if ( error.status === 409 ) {
          this.infoService.showSnackbar(MessageKey.GENERAL_DELETE_CONFLICT, InfoType.Warning);
          observer.next(false);
        } else {
          console.error(error);
          observer.error(error);
        }
        observer.complete();
      });
    });
  }

  emitInvalidatedEvent(invalid: boolean) {
    this._componentInvalidatedEvent$.next(invalid);
  }

  emitChangeEvent(event: OfflineContent.ComponentChangeEvent) {
    this._componentChangeEvent$.next(event);
  }

  fetchContent(contentId?: number): Observable<OfflineContentsResponse> {
    let url: string;

    if ( contentId > 0 ) {
      url = ApiUrls.getKey('OfflineContent')
        .replace(/{contentId}/gi, String(contentId));
    } else {
      url = ApiUrls.getKey('OfflineContents');
    }

    this._locations.reset();
    this._providers.reset();
    this._trainers.reset();
    return this.http.get<ApiResponse<OfflineContentsResponse>>(url)
      .pipe(map(response => response.data))
      .pipe(map(data => {
        if ( data.offlineContent != null ) {
          this.calculateViewData(data.offlineContent, data.upcomingEventSchedules, data.expiredEventSchedules);
          this.sortEvents(data.offlineContent);
          return data;
        }
        if ( data.offlineContents != null ) {
          data.offlineContents.forEach(offlineContent => {
            this.calculateViewData(offlineContent, data.upcomingEventSchedules, data.expiredEventSchedules);
            this.sortEvents(offlineContent);
          });
        }
        return data;
      }))
      .pipe(tap(data => {
        this._locations.next(data.locations);
        this._providers.next(data.providers);
        this._trainers.next(data.trainers);
      }));
  }

  findBookableOfflineContentTypes(bookableContents: boolean, contentType: OfflineContent.EventType,
    offlineEvents: { offlineContentType?: OfflineContent.EventType }[] = []): OfflineContent.BookableTypes {

    const offlineContentTypes: OfflineContent.BookableTypes = {};
    if (bookableContents) {
      offlineEvents
        .reduce((pV, event) => {
          if (event.offlineContentType === OfflineContent.EventType.virco) {

            // some kind of virtual event
            pV.virtual = true;
          } else if (event.offlineContentType != null) {

            // defined but not virtual -> interpret as offline event
            pV.offline = true;
          }
          return pV;
        }, offlineContentTypes);

      if (!(offlineContentTypes.virtual || offlineContentTypes.offline)) {
        if (contentType === OfflineContent.EventType.virco) {

          offlineContentTypes.virtual = true;
        } else {

          offlineContentTypes.offline = true;
        }
      }
    }

    return offlineContentTypes;
  }

  getAllRoles(): Observable<Array<OfflineContent.TrainerRoles>> {
    const url = ApiUrls.getKey('GetAllRoles');
    return this.http.get<ApiResponse<Array<OfflineContent.TrainerRoles>>>(url)
      .pipe(map(response => response.roles));
  }

  removeTrainer(trainer: OfflineContent.Trainer) {
    const newCollection = { ...this._trainers.value };
    delete newCollection[trainer.uuid];
    this._trainers.next(newCollection);
  }

  resetComponentChangeEvents() {
    this._componentChangeEvent$.reset();
  }

  saveEvent(event: OfflineContent.Event): Observable<OfflineContent.Event> {
    const url = ApiUrls.getKey('OfflineContents');

    const formData = new FormData();

    if ( event.$view != null ) {
      if ( event.$view.pictureFile != null ) {
        formData.append(
          'picture',
          event.$view.pictureFile,
          event.$view.pictureFile.name);
      }
      if ( event.$view.cardPictureFile != null ) {
        formData.append(
          'cardPicture',
          event.$view.cardPictureFile,
          event.$view.cardPictureFile.name);
      }
      if ( event.$view.participantListFile != null ) {
        formData.append(
          'participantListTemplate',
          event.$view.participantListFile,
          event.$view.participantListFile.name);
      }
      if ( event.$view.registrationConfirmationFile != null ) {
        formData.append(
          'registrationConfirmTemplate',
          event.$view.registrationConfirmationFile,
          event.$view.registrationConfirmationFile.name);
      }
      if (event.$view.participationConfirmationFile != null) {
        formData.append(
          'participationConfirmationTemplate',
          event.$view.participationConfirmationFile,
          event.$view.participationConfirmationFile.name);
      }
    }

    // prevent type from overriding eventType
    delete event['type'];
    formData.append('offlineContent', JSON.stringify(event, JsonOmitViewReplacer));

    return this.http.request<ApiResponse<OfflineContent.Event>>(
      'POST', url, { body: formData },
    ).pipe(map(response => {
        event.id = response.offlineContent.id;
        if ( response.success === true ) {
          this.infoService.showMessage($localize`:@@general_save_success:The data has been saved successfully`,
            { infoType: InfoType.Success });
        }
        return event;
      }),
    ).pipe(tap(content => {
      this._serviceEvent$.next({
        type: 'event_save',
        content
      });
    }));
  }

  saveEventSchedule(eventId: number, eventSchedule: OfflineContent.EventSchedule): Observable<OfflineContent.EventSchedule> {

    const url = ApiUrls.getKey('OfflineContent')
        .replace('{contentId}', String(eventId)) +
      '/event';

    const isNew = !(eventSchedule?.id > 0);

    const formData = new FormData();
    eventSchedule.active = true;
    if (eventSchedule.minUserCount == null) {
      eventSchedule.minUserCount = 0;
    }
    eventSchedule.attachments = AdminOfflineService
      .addFileInfoToFormData(formData, 'attachments', eventSchedule.attachments);
    eventSchedule.trainerAttachments = AdminOfflineService
      .addFileInfoToFormData(formData, 'trainerAttachments', eventSchedule.trainerAttachments);

    formData.append('offlineEvent', JSON.stringify(eventSchedule, JsonOmitViewReplacer));

    return this.http.request<ApiResponse<OfflineContent.EventSchedule>>(
      'POST', url, { body: formData },
    ).pipe(
      map(response => {
        merge(eventSchedule, response.offlineEvent);
        if (isNew) {
          // inject any new event schedule into content
          this._offlineEventAdded$.next(response.offlineEvent);
        }
        return eventSchedule;
      }),
    ).pipe(tap(content => {
      this._serviceEvent$.next({
        type: 'event_shedule_save',
        content
      });
    }));
  }

  public static addFileInfoToFormData(
    formData: FormData, fieldName: string, attachments: Array<FileInfo>,
  ): Array<FileInfo> {
    return attachments?.map(fileInfo => {
      if (fileInfo instanceof UploadFile) {
        formData.append(fieldName, fileInfo.file);
        return {
          uuid: fileInfo.uuid,
          fileName: fileInfo.fileName,
          fileSize: fileInfo.fileSize,
          mime: fileInfo.mime,
        };
      }
      return fileInfo;
    });
  }

  saveCondition(conditions: FileInfo): Observable<FileInfo> {
    const url = ApiUrls.getKey('Files_Upload')
      .replace(/{authType}/gi, 'unchecked');
    return this.http.post<ApiResponse<FileInfo>>(url, conditions)
      .pipe(map(response => response.file));
  }

  savePrivacy(privacy: FileInfo) {
    const url = ApiUrls.getKey('Files_Upload')
    .replace(/{authType}/gi, 'unchecked');
  return this.http.post<ApiResponse<FileInfo>>(url, privacy)
    .pipe(map(response => response.file));
  }

  getColumnSettings(): Observable<ColumnSettings[]> {
    const url = ApiUrls.getKey('OfflineColumns_V2');
    return this.http.get<ApiResponse<ColumnSettings[]>>(url)
      .pipe(map(response => response.columnSettings));
  }

  getTags(): Observable<OfflineContent.TagTitle[]> {
    const url = ApiUrls.getKey('Tags');
    return this.http.get<ApiResponse<OfflineContent.TagTitle[]>>(url)
      .pipe(map(response => response.tags));
  }

  setEventScheduleComplitionStatus(
    eventId: number, eventSchedule: Pick<OfflineContent.EventSchedule, 'id' | 'closedStatus'>,
    status: OfflineContent.ClosedStatus,
  ): Observable<boolean> {
    const payload = { status };
    const url = `${ApiUrls.getKey('OfflineContents')}/${eventId}/event/${eventSchedule.id}/status`;
    return this.http
      .post<ApiResponse<void>>(url, JSON.stringify(payload))
      .pipe(map(response => response.success))
      .pipe(tap(success => {
        if ( success ) {
          eventSchedule.closedStatus = status;
        }
      }));
  }

  updateLocation(location: OfflineContent.Location, persist = false): Observable<OfflineContent.Location> {

    return new Observable(observer => {

      const closure = (_location: OfflineContent.Location) => {
        const newCollection = { ...this._locations.value };
        newCollection[_location.uuid] = _location;
        this._locations.next(newCollection);

        observer.next(_location);
        observer.complete();
      };

      this.updateAdditionalData(persist, 'Location', 'location', location.uuid, location, closure, observer);
    });
  }

  updateProvider(provider: OfflineContent.Provider, persist = false): Observable<OfflineContent.Provider> {
    return new Observable(observer => {

      const closure = (_provider: OfflineContent.Provider) => {
        const newCollection = { ...this._providers.value };
        newCollection[_provider.uuid] = _provider;
        this._providers.next(newCollection);

        observer.next(_provider);
        observer.complete();
      };

      this.updateAdditionalData(persist, 'Providers', 'provider', provider.uuid, provider, closure, observer);
    });
  }

  updateTrainer(trainer: OfflineContent.Trainer, persist = false): Observable<OfflineContent.Trainer> {

    return new Observable<OfflineContent.Trainer>(observer => {

      const closure = (_trainer) => {
        const newCollection = { ...this._trainers.value };
        newCollection[trainer.uuid] = trainer;
        this._trainers.next(newCollection);

        observer.next(_trainer);
        observer.complete();
      };

      this.updateAdditionalData(persist, 'Trainer', 'trainer', trainer.uuid, trainer, closure, observer);
    })
      .pipe(switchMap(response => this.markTrainerPictureAsInUse(trainer, response)));
  }

  usage(usageType: OfflineContent.UsageType, usageUUID: string): Observable<Array<OfflineContent.Event>> {
    const url = `${ApiUrls.getKey('Offline')}/${usageType}/${usageUUID}/usage`;
    return this.http.get<ApiResponse<Array<OfflineContent.Event>>>(url)
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        return EMPTY;
      }))
      .pipe(map(response => response.offlineContents));
  }

  usageConfirm(usageType: OfflineContent.UsageType, usageUUID: string, action: 'edit' | 'remove', contentId?: number): Observable<number> {
    return this.usage(usageType, usageUUID)
      .pipe(map(usages => ((usages || []))
        // ignore current offlineContent
        .filter(evt => evt.id !== contentId)
        // todo replace AdminOfflineApi response with Usage type
        .map(evt => ({
            active: evt.active,
            objectId: evt.id,
            objectTitle: evt.title,
            objectType: ObjectUsageTypes.ObjectType.OfflineContent,
          } as ObjectUsageTypes.Usage))))
      // warn before editing
      .pipe(switchMap(usages => {
        if ( usages.length > 0 ) {
          const activeUsageCount = usages.filter(entry => entry.active === true).length;
          return this.dialog.open<any, UsageDialogData, boolean>(UsageDialogComponent, {
            data: { usages, action },
          }).afterClosed()
            .pipe(takeWhile(confirmed => !!confirmed))
            .pipe(map(() => activeUsageCount));
        } else {
          return of(0);
        }
      }));
  }

  getOfflineContents(): Observable<any[]> {
    const url = ApiUrls.getKey('OfflineContents');
    return this.http.get<ApiResponse<any[]>>(url)
      .pipe(map(response => response.data));
  }

  listOfflineContents(excludeBlockEvents = false): Observable<Array<ContentReference>> {
    const url = ApiUrls.getKey('OfflineContentsList') + '?excludeBlockEvents=' + excludeBlockEvents;
    return this.http.get<ApiResponse<any[]>>(url)
      .pipe(map(response => response.data));
  }

  private markTrainerPictureAsInUse(trainer: OfflineContent.Trainer, response: OfflineContent.Trainer) {
    if ( (trainer?.$view?.trainerPicture == null) || (trainer?.pictureUUID == null) ) {
      return of(response);
    }

    return this.fileUploadService
      .uploadFileV2MarkAsUsed(trainer.pictureUUID, FileAuthorizationRefType.f2fcontent_pic)
      .pipe(mapTo(response));
  }

  private calculateViewData = (
    offlineContent: OfflineContent.Event,
    upcomingEvents: Array<number>,
    expiredEvents: Array<number>,
  ) => {

    offlineContent.$view = {};
    if ( offlineContent.offlineEvents && offlineContent.offlineEvents.length > 0 ) {
      offlineContent.offlineEvents.forEach(offlineEvent => {
        this.createViewDataForEventSchedule(offlineEvent);
        offlineEvent.$view.upcomingEvents = upcomingEvents;
        offlineEvent.$view.expiredEvents = expiredEvents;
      });
    }
  };

  private sortEvents(offlineContent: OfflineContent.Event) {
    if ( offlineContent.offlineEvents && offlineContent.offlineEvents.length > 0 ) {
      offlineContent.offlineEvents = offlineContent.offlineEvents.sort((event1, event2) => {
        const diff = event2.$view.startDate.diff(event1.$view.startDate, 'days');
        if ( diff > 0 ) {
          return -1;
        } else if ( diff < 0 ) {
          return 1;
        }
        const diffInEndDate = event2.$view.endDate.diff(event1.$view.endDate, 'days');
        if ( diffInEndDate > 0 ) {
          return -1;
        } else if ( diffInEndDate < 0 ) {
          return 1;
        }
        return event1.id - event2.id;
      });
    }
  }

  private updateAdditionalData = <T>(
    persist: boolean,
    apiKey: string,
    responseAttr: string,
    entryUuid: string,
    data: T,
    closure: (data: T) => void, observer: Subscriber<T>) => {

    if ( persist ) {
      const url = ApiUrls.getKey(apiKey) + `/${entryUuid}`;
      this.http.post<ApiResponse<T>>(url, JSON.stringify(data))
      .pipe(catchError(e => {
        observer.error(e);
        observer.complete();
        return e;
      }))
      .subscribe(response => {
        closure(response[responseAttr]);
      });
    } else {
      closure(data);
    }
  };

}
