import { PermissionHelper } from './permission-helper';
import { RuntimeEnvironment } from '../runtime-environment.service';

const ANG_COURSE_ADMIN = 'ngacrs';
const ANG_LAYOUT_ADMIN = 'laya';
const EDIT_FRONTEND_CONFIG = 'efc';
const BASE_AC_ACTIONCODEADMIN = 'aca';
const BASE_AC_ACTIONCODEREDUCEDADMIN = 'acra';
const BASE_ACC_ADMIN_CREATE = 'acca';
const BASE_ACC_ADMIN_EDIT = 'acce';
const BASE_ACC_ADMIN_READ = 'accr';
const BASE_ARTA = 'arta';
const BASE_ARTR = 'artr';
const BASE_MYTRAIN_BY_CATEGORY = 'mtbc';
const BASE_MYTRAIN_MANDATORY = 'mtmc';
const BASE_MYTRAIN_PERSFILES = 'mtpf';
const BASE_MYTRAIN_PLANNED = 'mtpl';
const BASE_MYTRAIN_SETTINGS = 'mtse';
const BASE_MYTRAIN_VOLUNTARY = 'mtvc';
const BASE_SSO = 'sso';
const BASE_TG_ADMINCREATE = 'tgac';
const BASE_TG_ADMINDELETE = 'tgad';
const BASE_TG_ADMINFILTERCREATE = 'tgafc';
const BASE_TG_ADMINGENERAL = 'tgag';
const BASE_TG_ADMINREAD = 'tgar';
const BASE_UPID = 'upid';
const BASE_USER = 'Basis_User';
const BASE_USR_REDUCED_USER_CREATE = 'ruac';
const BASE_USR_REDUCED_USER_EDIT = 'ruae';
const LSTD_IMPORTER = 'lstd';
/**
 * @deprecated replaced by "ugar"
 */
const BASE_USR_USER_CREATE = 'uac';
const BASE_USR_USER_CSV_IMPORT = 'ucsv';
/**
 * @deprecated replaced by "ugar"
 */
const ucsv_BASE_USR_USER_CSV_IMPORT = 'ucsv';
const BASE_USR_USER_DELETE = 'uad';
const ugar_BASE_USR_GENERAL_USER_ADMIN = 'ugar';
const BASE_USR_USER_REACTIVATE = 'uare';
const BASE_USR_USER_SERIAL_LETTERS = 'usle';
const BASE_USR_USER_SINGLE_IMPORT = 'usiu';
export const CAT_USER = 'cat';
const CAT_ADMIN = 'cata';
const Controlling_clsctrl_CLASSIC = 'clsctrl';
const Controlling_ADMIN_REPORT_CREATE = 'repc';
const Controlling_COURSES_EDIT = 'coce';
const Controlling_COURSES_LOCK = 'cocl';
const Controlling_COURSES_READ = 'cocr';
const Controlling_CURRICULUM_EDIT = 'cuce';
const Controlling_CURRICULUM_EDIT_WITHOUT_MARKED_ITEMS = 'cucwomie';
const Controlling_CURRICULUM_LOCK = 'cucl';
const Controlling_CURRICULUM_READ = 'cucr';
const Controlling_GENERAL_CERT_UPLOAD = 'cogcrtupld';
const Controlling_GENERAL_COURSES = 'cogco';
const Controlling_GENERAL_COURSES_READ = 'cogrco';
const Controlling_GENERAL_CURRICULA = 'cogcu';
const Controlling_GENERAL_CURRICULA_ACCOUNT = 'cogca';
const Controlling_GENERAL_CURRICULA_READ = 'cogrcu';
const Controlling_GENERAL_OFFLINE = 'cogof';
const Controlling_GENERAL_TARGETGROUP = 'cogtg';
const Controlling_GENERAL_TARGETGROUP_READ = 'cogtgr';
const Controlling_GENERAL_WORKFLOWS = 'cogwf';
const Controlling_REPORT_READ = 'repr';
const Controlling_REPORT_GENERATOR_ADMIN = 'repa';
const Controlling_REPORT_GENERATOR_CURRICULUM = 'corgcu';
const Controlling_REPORT_GENERATOR_COURSES = 'corgco';
const Controlling_REPORT_GENERATOR_OFFLINE_corgoff = 'corgoff';
const Controlling_REPORT_GENERATOR_DASHBOARD = 'corgdash';
const Controlling_REPORT_GENERATOR_UNLIMITED_SAVES = 'corgus';
const Controlling_SINGLE_USER_EDIT = 'coasue';
const Controlling_SINGLE_USER_IN_TG_EDIT = 'cosue';
const Controlling_SINGLE_USER_IN_TG_READ = 'cosur';
const Controlling_SINGLE_USER_READ = 'coasur';
const Controlling_WORKFLOW_EDIT = 'cowe';
const Controlling_WORKFLOW_LOCK = 'cowl';
const Controlling_WORKFLOW_READ = 'cowr';
const Controlling_ZUEP = 'avszuep';
const Controlling_FURTHER_BOARDS = 'avszueplist';
const Controlling_FURTHER_BOARD_01 = 'specdb01';
const Controlling_FURTHER_BOARD_02 = 'specdb02';
const Controlling_FURTHER_BOARD_03 = 'specdb03';
const Controlling_FURTHER_BOARD_04 = 'specdb04';
const Controlling_SINGLE_LISTS_ngsinlis = 'ngsinlis';
const CREATE_USER = 'crt';
const DOCLIB_READ = 'crtm';
const DOCLIB_ADMIN = 'dlad';
const DOCLIB_FOLDER_ADMIN = 'dlfa';
const DOCLIB_USER = 'dlus';
const DST_CONT_GENERAL = 'cag';
const DST_CONT_INVOICES = 'cia';
const DST_CONT_LIGHT = 'cal';
const DST_DIST_LIC_GROUP_FOR_TG = 'dstltg';
const DST_DIST_LICENCE_GROUP = 'dstlg';
const DST_DISTRIBUT = 'dst';
const EHS_ADMIN = 'ear';
const JOURNAL_READ = 'ijr';
const ELEA_BY_CATEGORY = 'mtbc';
const ELEA_CRS_EXCHANGE = 'crsx';
const ELEA_CRS_GENERALS = 'crsg';
const ELEA_CUR_BEHAVIOUR = 'curbh';
const ELEA_CUR_COPY = 'curco';
const ELEA_CUR_CREATE = 'curc';
const ELEA_CUR_DELETE = 'curd';
const ELEA_CUR_EDIT = 'cure';
const ELEA_CUR_TODO = 'crstodo';
const ELEA_CUR_GENERAL = 'curg';
const ELEA_CUR_READ = 'curr';
const ELEA_COCKPIT_GENERAL = 'coptg';
const ELEA_COCKPIT_READ = 'coptr';
const ELEA_DEPLOY_ADMIN_CREATE = 'depc';
const ELEA_DEPLOY_ADMIN_DELETE = 'depd';
const ELEA_DEPLOY_ADMIN_REDEPLOY = 'deprd';
const ELEA_DEPLOY_COURSES = 'depr';
const ELEA_GENERAL_CRS = 'crsg';
const ELEA_LEARNER = 'lern';
const ELEA_MT_MAN_COURSES = 'mtmc';
const ELEA_MT_PLANNED_COURSES = 'mtpl';
const ELEA_MT_VOL_COURSES = 'mtvc';
const ELEA_MT_OFFLINE_CONTENT = 'mtoffc';
const ELEA_MY_CERTS = 'mycts';
const LCM_ADMIN = 'lcmadm';
const LCM_PUBLISH = 'lcmpub';
const LCM_RELEASE = 'lcmrel';
const LCM_REVISE = 'lcmrev';
const LCM_SUBMIT = 'lcms';
const LICENCE_REQUEST_OFFLINE_DELETE = 'lirqdoff';
const LICENCE_REQUEST_OFFLINE_EDIT = 'lirqeoff';
const LICENCE_REQUEST_OFFLINE_READ = 'lirqroff';
const LICENCE_REQUEST_ONLINE_DELETE = 'lirqd';
const LICENCE_REQUEST_ONLINE_EDIT = 'lirqe';
const LICENCE_REQUEST_ONLINE_READ = 'lirqr';
const MSG_READ_MSGS = 'musi';
const MSG_SAVE_DRAFTS = 'mdrf';
const MSG_TIMED_MSGS = 'mtim';
const MSG_USE_MACROS = 'mmac';
const MSG_WRITE_MSGS = 'mwrt';
const MSG_WRITE_MSGS_TO_OWN_CC = 'mwcc'; // costcenter (Debitoren-Nr.)
const MSG_WRITE_MSGS_TO_TG = 'mwtg';
const NGNC_NO_NAV_TO_CLASSIC = 'ngnc';
const OFF_ADMIN_CONTENT_CREATE = 'offcc';
const OFF_ADMIN_CONTENT_GENERAL = 'offc';
const OFF_ADMIN_EVENT_CREATE = 'offec';
const OFF_ADMIN_EVENT_GENERAL = 'offe';
const OFF_ADMIN_GENERAL = 'offa';
const OFF_ADMIN_LOCATIONS = 'offl';
const OFF_ADMIN_PROVIDERS = 'offp';
const OFF_ADMIN_TRAINER = 'offt';
const OFFPTG_ADMIN_PRICES_PER_TG = 'offptg';
const OFF_ADMIN_NEXT_GEN = 'offnga';
const OFF_CTRL_CONTENT_ASSIGNMENT = 'offca';
export const offptcpd_OFF_CTRL_LIST_PARTICIPANTS_DENIAL = 'offptcpd';
const OFF_CTRL_EDIT_STATUS_ONLY = 'offso';
const OFF_CTRL_EVENT_ASSIGN_AND_EDIT_STATUS = 'offea';
const OFF_CTRL_GENERAL = 'offac';
const OFF_CTRL_NEXT_GEN = 'offngc';
const OFF_CTRL_LIST_EDIT = 'ocle';
const OFF_CTRL_LIST_READ = 'oclr';
const OFF_CTRL_PRCP_READ_DENIAL = 'ngnoptcp';
const SCORM_TRACKING_IS_OFF = 'scoprev';
const ruattra_ADMIN_LIMITED_USER_ADMIN = 'ruattra';
/**
 * @deprecated should not be in use
 */
const rac_ROLE_ADMIN_CREATE = 'rac';
const rar_ROLE_ADMIN_GENERAL = 'rar';
/**
 * @deprecated remove where possible
 */
const REFLACT = 'Reflact';
const REP_CREATE = 'fdc';
const REP_DELETE = 'fdd';
const REP_GENERAL_READ = 'fadr';
const REP_READ = 'fdr';
const REP_WRITE = 'fde';
const SYSTEM = 'System';
const NEXT_GEN_CUSTOM_COLORS = 'ngcc';
const NEXT_GEN_CUSTOM_COLORS_ADMIN = 'ngcca';
const NEXT_GEN_SELFT_REGISTRATION = 'ngaslfrg';
const NGSSOG_NEXT_GEN_SSO_ADMIN = 'ngssog';
const BASE_ADOBE_CONNECT_ADMIN = 'adbcnt';
const NEXT_GEN_EXT_SERVER_ADMIN = 'exsva';
const PAYMENT_PMNTG_GENERAL_ADMIN = 'pmntg';
const PAYMENT_PMNTG_READ = 'pmntr';
const NEXT_GEN_QA_READ = 'nguqar';
const NEXT_GEN_QA_ASK_QUESTION = 'nguqaaq';
const NEXT_GEN_QA_MODERATOR = 'nguqam';
const ADMIN_GAMIFICATION = 'gama';
const NEXT_GEN_BETA_FE = 'ngbetafrontend';
const NEXT_GEN_BETA_CARDS = 'ngbetacards'

const USR_PROFILE_DEACTIVATE = 'dou';

const SUPERIOR_FEEDBACK_ADMIN = 'supfa';
const SUPERIOR_FEEDBACK_SUPERIOR = 'supfs';

const nghg_NEXT_GEN_HANDBOOK_GENERAL = "nghg";
const nghr_NEXT_GEN_HANDBOOK_READ = "nghr";

export class PermissionStates {

  private readonly _environment: RuntimeEnvironment;
  private readonly _cache: { [key: string]: boolean } = {};
  private readonly _hideForIlearn: boolean;
  private readonly _isLogged: boolean;
  private readonly _roles: string[];
  private readonly _showPreAlpha: boolean;
  private readonly _showBeta: boolean;

  constructor(
    isLogged: boolean,
    roles: string[],
    environment: RuntimeEnvironment,
    showBeta?: boolean
  ) {
    this._environment = environment;
    this._isLogged = isLogged;
    this._showPreAlpha = environment?.showPreAlphaNavigation ?? false;
    this._roles = roles || [];
    this._showBeta = showBeta ??
      (environment?.showBetaNavigation && PermissionHelper.hasAnyRole(this._roles, 'ngbeta'));
    this._hideForIlearn = (environment?.hideForIlearn ?? true)
      && !PermissionHelper.hasAnyRole(this._roles, 'ngilearn');
  }

  static cloneWithBeta(state: PermissionStates, environment: RuntimeEnvironment, showBeta: boolean): PermissionStates {
    return new PermissionStates(state.isLogged, state._roles, environment, showBeta);
  }

  get hideForILearn(): boolean {
    return this._hideForIlearn;
  }

  get showAlpha(): boolean {
    return this._showPreAlpha;
  }

  get showBeta(): boolean {
    return this._showBeta;
  }

  get showBetaToggle(): boolean {
    return this.checkPermission('showBetaToggle', () => PermissionHelper.hasAnyRole(this._roles, [
      'ngbeta',
    ]));
  }

  get adminActionCodes(): boolean {
    return this.checkPermission('adminActionCodes', () => PermissionHelper.hasAnyRole(this._roles, [
      BASE_AC_ACTIONCODEADMIN,
    ]));
  }

  get adminActionCodesReduced(): boolean {
    return this.checkPermission('adminActionCodesReduced', () => PermissionHelper.hasAnyRole(this._roles, [
      BASE_AC_ACTIONCODEADMIN,
      BASE_AC_ACTIONCODEREDUCEDADMIN,
    ]));
  }

  /**
   * see com.reflact.sphere.elearning.deployment.DeployCoursesAction#addCourse
   */
  get adminCourseCreate(): boolean {
    return this.checkPermission('adminCourseCreate', () => PermissionHelper.hasAnyRole(this._roles, [
        REFLACT,
        ELEA_CRS_GENERALS,
        ELEA_DEPLOY_ADMIN_CREATE,
      ]));
  }

  /**
   * Advanced Settings == Behaviours
   */
  get adminCurriculumAdvSettings(): boolean {
    return this.checkPermission('adminCurriculumAdvSettings', () => PermissionHelper.hasAnyRole(this._roles, [
      ELEA_CUR_BEHAVIOUR,
    ]));
  }

  get adminCurriculum(): boolean {
    return this.checkPermission('adminCurriculum', () => {
      if(this.hideForILearn){
        return PermissionHelper.hasAnyRole(this._roles, [
          'SUPER_ADMIN',
        ]) && PermissionHelper.hasAnyRole(this._roles, [
          ELEA_CUR_CREATE,
          ELEA_CUR_READ,
          ELEA_CUR_GENERAL,
          REFLACT,
        ]);
      }
      return PermissionHelper.hasAnyRole(this._roles, [
        ELEA_CUR_CREATE,
        ELEA_CUR_READ,
        ELEA_CUR_GENERAL,
        REFLACT,
      ]);
    });

  }

  get adminCurriculumCopy(): boolean {
    return this.checkPermission('adminCurriculumCopy', () => PermissionHelper.hasAnyRole(this._roles, [
      ELEA_CUR_COPY,
    ]));
  }

  get adminCurriculumCreate(): boolean {
    return this.checkPermission('adminCurriculumCreate', () => PermissionHelper.hasAnyRole(this._roles, [
        ELEA_CUR_CREATE,
        ELEA_CUR_GENERAL,
      ]));
  }

  get adminCurriculumDelete(): boolean {
    return this.checkPermission('adminCurriculumDelete', () => PermissionHelper.hasAnyRole(this._roles, [
        ELEA_CUR_DELETE,
        ELEA_CUR_GENERAL,
      ]));
  }

  get adminNotifications(): boolean {
    return this.checkPermission('adminNotifications', () => PermissionHelper.hasAnyRole(this._roles, [
        EHS_ADMIN,
      ]));
  }

  get adminOffline(): boolean {
    return this.checkPermission('adminOffline', () => PermissionHelper.hasAnyRole(this._roles, [
      OFF_ADMIN_GENERAL,
      OFF_ADMIN_EVENT_GENERAL,
      OFF_ADMIN_CONTENT_GENERAL,
      OFF_ADMIN_CONTENT_CREATE,
      OFF_ADMIN_EVENT_CREATE,
    ]));
  }

  get adminOfflinePricesPerTG(): boolean {
    return !this.hideForILearn && this.checkPermission('adminOfflinePricesPerTG', () => PermissionHelper.hasAnyRole(this._roles, [
      OFFPTG_ADMIN_PRICES_PER_TG,
    ]));
  }

  get adminPaymentGeneral(): boolean {
    return this.checkPermission('adminPaymentGeneral', () => PermissionHelper.hasAnyRole(this._roles, [
        PAYMENT_PMNTG_GENERAL_ADMIN,
      ]));
  }

  get adminRoles(): boolean {
    return this.checkPermission('adminRoles', () => this.adminRolesCreate || this.adminRolesGeneral);
  }

  get navAdminUsersReduced(): boolean {
    return this.checkPermission('navAdminUsersReduced', () => !this.adminRoles && PermissionHelper.hasAnyRole(this._roles, [
        BASE_USR_REDUCED_USER_CREATE,
        BASE_USR_REDUCED_USER_EDIT,
      ]));
  }

  get navAdminReducedUserAdministration(): boolean {
    return this.checkPermission('navAdminReducedUserAdministration', () => PermissionHelper.hasAnyRole(this._roles, [
        ruattra_ADMIN_LIMITED_USER_ADMIN,
        rar_ROLE_ADMIN_GENERAL,
      ]));
  }

  /**
   * @deprecated should not be in use
   */
  get adminRolesCreate(): boolean {
    return this.checkPermission('adminRolesCreate', () => PermissionHelper.hasAnyRole(this._roles, [
        rar_ROLE_ADMIN_GENERAL,
        rac_ROLE_ADMIN_CREATE,
      ]));
  }

  get adminRolesGeneral(): boolean {
    return this.checkPermission('adminRolesGeneral', () => PermissionHelper.hasAnyRole(this._roles, [
        rar_ROLE_ADMIN_GENERAL,
      ]));
  }

  get adminTargetGroupCreate(): boolean {
    return this.checkPermission('adminTargetGroupCreate', () => PermissionHelper.hasAnyRole(this._roles, [
        BASE_TG_ADMINCREATE,
        BASE_TG_ADMINGENERAL,
      ]));
  }

  get adminTargetGroupGeneral(): boolean {
    return this.checkPermission('adminTargetGroupGeneral', () => PermissionHelper.hasAnyRole(this._roles, [
        BASE_TG_ADMINGENERAL,
      ]));
  }

  get adminReducedUserAdmin(): boolean {
    return this.checkPermission('adminReducedUserAdmin', () => !PermissionHelper.hasAnyRole(this._roles, [
      ugar_BASE_USR_GENERAL_USER_ADMIN,
    ]) && PermissionHelper.hasAnyRole(this._roles, [
      BASE_USR_REDUCED_USER_CREATE,
      BASE_USR_REDUCED_USER_EDIT,
    ]));
  }

  get adminUserGeneral(): boolean {
    return this.adminReducedUserAdmin || this.checkPermission('adminUserGeneral', () => PermissionHelper.hasAnyRole(this._roles, [
        ugar_BASE_USR_GENERAL_USER_ADMIN,
        BASE_USR_USER_CREATE,
      ]));
  }

  get saveDefaultColumnSettings(): boolean {
    return this.navAdminAccountDesign;
  }

  get ctrlAssignment(): boolean {
    return this.checkPermission('ctrlAssignment', () => this.ctrlCourseAssignment || this.ctrlCurriculumAssignment || this.ctrlOfflineAssignment);
  }

  get ctrlCourseAssignment(): boolean {
    return this.checkPermission('ctrlCourseAssignment', () => PermissionHelper.hasAnyRole(this._roles, [
        REFLACT,
        'dco',
        DST_DISTRIBUT,
        DST_DIST_LICENCE_GROUP,
        'dwf',
      ]));
  }

  get ctrlCurriculumAssignment(): boolean {
    return this.checkPermission('ctrlCurriculumAssignment', () => PermissionHelper.hasAnyRole(this._roles, [
        'dco',
        DST_DISTRIBUT,
        DST_DIST_LICENCE_GROUP,
      ]));
  }

  get ctrlCourseReset(): boolean {
    return this.checkPermission('ctrlCourseReset', () => PermissionHelper.hasAnyRole(this._roles, [
        REFLACT,
        Controlling_COURSES_READ,
        Controlling_COURSES_EDIT,
        Controlling_GENERAL_COURSES,
        'cogcor',
      ]));
  }

  get allDists(): boolean {
    return this.checkPermission('allDists', () => PermissionHelper.hasAnyRole(this._roles, [
      DST_DISTRIBUT,
      DST_DIST_LICENCE_GROUP,
      DST_DIST_LIC_GROUP_FOR_TG,
    ]));
  }

  get ctrlOfflineAssignment(): boolean {
    return this.checkPermission('ctrlOfflineAssignment', () => this.allDists && PermissionHelper.hasAnyRole(this._roles, [
        // specific permissions for controlling of offline contents
        REFLACT,
        OFF_CTRL_CONTENT_ASSIGNMENT,
        OFF_CTRL_EVENT_ASSIGN_AND_EDIT_STATUS,
        OFF_CTRL_GENERAL,
      ]));
  }

  get ctrlOfflineEditStatus(): boolean {
    return this.checkPermission('ctrlOfflineEditStatus', () => PermissionHelper.hasAnyRole(this._roles, [
        OFF_CTRL_EDIT_STATUS_ONLY,
        OFF_CTRL_EVENT_ASSIGN_AND_EDIT_STATUS,
        OFF_CTRL_GENERAL,
      ]));
  }

  get ctrlReportGeneratorUnlimited(): boolean {
    return this.checkPermission('ctrlReportGeneratorUnlimited', () => this.navCtrlReportGenerator &&
        PermissionHelper.hasAnyRole(this._roles, [
          REFLACT,
          Controlling_REPORT_GENERATOR_UNLIMITED_SAVES,
        ]));
  }

  get forbidden(): boolean {
    return false;
  }

  get adminGamification(): boolean {
    return this.checkPermission('adminGamification', () => PermissionHelper.hasAnyRole(this._roles, [
      ADMIN_GAMIFICATION,
    ]));
  }

  get navAdminGamification(): boolean {
    return this.adminGamification;
  }

  get navAdminHandbook(): boolean {
    return this.navAdminHandbookSettings || this.navAdminHandbookRead;
  }

  get navAdminHandbookSettings(): boolean {
    return this.showBeta &&
      this.checkPermission('navAdminHandbookSettings', () => PermissionHelper.hasAnyRole(this._roles, [
        nghg_NEXT_GEN_HANDBOOK_GENERAL,
      ]));
  }

  get navAdminHandbookRead(): boolean {
    return this.showBeta &&
      this.checkPermission('navAdminHandbookRead', () => PermissionHelper.hasAnyRole(this._roles, [
        nghg_NEXT_GEN_HANDBOOK_GENERAL,
        nghr_NEXT_GEN_HANDBOOK_READ,
      ]));
  }

  get hasAdobeConnectAdmin(): boolean {
    return this.checkPermission('hasAdobeConnectAdmin', () => PermissionHelper.hasAllRoles(this._roles, [
        BASE_ADOBE_CONNECT_ADMIN,
      ]));
  }

  get hasCtrlCurriculumGeneralRead(): boolean {
    return this.checkPermission('hasCtrlCurriculumGeneralRead', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_GENERAL_CURRICULA,
        Controlling_GENERAL_CURRICULA_READ,
      ]));
  }

  get hasCtrlEditCourseAccount(): boolean {
    return this.checkPermission('hasCtrlEditCourseAccount', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_COURSES_EDIT,
        Controlling_GENERAL_COURSES,
        Controlling_SINGLE_USER_EDIT,
        Controlling_GENERAL_TARGETGROUP,
        Controlling_SINGLE_USER_IN_TG_EDIT,
      ]));
  }

  get hasCtrlEditCurriculumAccount(): boolean {
    return this.checkPermission('hasCtrlEditCurriculumAccount', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_CURRICULUM_EDIT,
        Controlling_GENERAL_CURRICULA,
        Controlling_GENERAL_CURRICULA_ACCOUNT,
        Controlling_SINGLE_USER_EDIT,
        Controlling_GENERAL_TARGETGROUP,
        Controlling_SINGLE_USER_IN_TG_EDIT,
        Controlling_CURRICULUM_EDIT_WITHOUT_MARKED_ITEMS,
      ]));
  }

  get showNewUserInterfaceCards(): boolean {
    return this.checkPermission('showNewUserInterfaceCards', () => this._environment?.showNewCardDesign ||
      PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_BETA_CARDS,
      ]));
  }

  /**
   * @deprecated use {@link PermissionStates.hasCtrlEditCurriculumAccount} instead
   */
  get hasCtrlSwitchPath(): boolean {
    return this.hasCtrlEditCurriculumAccount;
  }

  get isGuest(): boolean {
    return !this._isLogged;
  }

  get isLogged(): boolean {
    return this._isLogged;
  }

  get navAdmin(): boolean {
    return this.checkPermission('navAdmin', () => this.navAdminAccountDesign ||
        this.navAdminCatalog ||
        this.navAdminCockpits ||
        this.navAdminCourse ||
        this.navAdminDistribution ||
        this.navAdminExternal ||
        this.navAdminOffline ||
        this.navAdminNews ||
        this.navAdminReportGenerator ||
        this.navAdminUserAdministration ||
        this.navAdminSelfRegistration ||
        this.navAdminPermissions ||
        this.navAdminUsers ||
        this.navAdminUsersReduced ||
        this.navAdminReducedUserAdministration ||
        this.navAdminSuperiorFeedback ||
        this.navAdminQA);
  }

  get navAdminAccountDesign(): boolean {
    return this.checkPermission('navAdminAccountDesign', () => PermissionHelper.hasAnyRole(this._roles, [
        ANG_LAYOUT_ADMIN,
        REFLACT,
      ]));
  }

  get navAdminAccountDesignLogin(): boolean {
    return this.checkPermission('navAdminAccountDesign', () => this.navAdminAccountDesign && PermissionHelper.hasAnyRole(this._roles, [
        EDIT_FRONTEND_CONFIG,
      ]));
  }

  get navAdminConfiguration(): boolean {
    return this.checkPermission('navAdminConfiguration', () => this.navAdminReportGenerator || this.navAdminAccountDesign);
  }

  get navAdminCourse(): boolean {
    return this.checkPermission('navAdminCourse', () => PermissionHelper.hasAnyRole(this._roles, [
        ANG_COURSE_ADMIN,
      ]));
  }

  get navAdminCurriculum(): boolean {
    return this.adminCurriculum;
  }

  get navAdminCurriculumBilling(): boolean {
    return this.checkPermission('navAdminCurriculumBilling', () => this.hideForILearn && PermissionHelper.hasAnyRole(this._roles, [
      'SUPER_ADMIN',
    ]));
  }

  get navAdminCockpits(): boolean {
    return this.checkPermission('navAdminCockpits', () => this.adminCurriculum && this.navAdminOfflineContent && PermissionHelper.hasAnyRole(this._roles, [
        ELEA_COCKPIT_READ,
        ELEA_COCKPIT_GENERAL,
      ]));
  }

  get navAdminNotifications(): boolean {
    return this.checkPermission('navAdminNotifications', () => this.adminNotifications);
  }

  get navAdminQA(): boolean {
    return !this._hideForIlearn && this.checkPermission('navAdminQA', () => PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_QA_MODERATOR,
      ]));
  }

  get navAdminDistribution(): boolean {
    return this.checkPermission('navAdminDistribution', () => this.navAdminDistributionLimited);
  }

  get navAdminDistributionLimited(): boolean {
    return this.checkPermission('navAdminDistributionLimited', () => PermissionHelper.hasAnyRole(this._roles, [
        DST_DIST_LIC_GROUP_FOR_TG,
      ]));
  }

  get navAdminDistributionOffline(): boolean {
    return this.checkPermission('navAdminDistributionOffline', () => PermissionHelper.hasAnyRole(this._roles, [
        'dco',
        'dwf',
        DST_DISTRIBUT,
        DST_DIST_LICENCE_GROUP,
        REFLACT,
      ]));
  }

  get navAdminExternal(): boolean {
    return this.checkPermission('navAdminExternal', () => this.navAdminExternalLogin ||
        this.navAdminExternalDSBGateway ||
        this.navAdminJavascript ||
        this.navAdminExternalOAuth2);
  }

  get navAdminExternalLogin(): boolean {
    return this.checkPermission('navAdminExternalLogin', () => PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_EXT_SERVER_ADMIN,
        REFLACT,
      ]));
  }

  get navAdminExternalOAuth2(): boolean {
    return this.checkPermission('navAdminExternalOAuth2', () => PermissionHelper.hasAnyRole(this._roles, [
        NGSSOG_NEXT_GEN_SSO_ADMIN,
      ]));
  }

  get navAdminExternalDSBGateway(): boolean {
    return this.checkPermission('navAdminExternalDSBGateway', () => !this.hideForILearn &&
        PermissionHelper.hasAnyRole(this._roles, [
          PAYMENT_PMNTG_GENERAL_ADMIN,
          PAYMENT_PMNTG_READ,
        ]));
  }

  get navAdminJavascript(): boolean {
    return !this._hideForIlearn && this.checkPermission('navAdminJavascript', () => PermissionHelper.hasAnyRole(this._roles, [
        BASE_ACC_ADMIN_EDIT,
      ]));
  }

  // todo add permissions
  get navAdminTextMacros(): boolean {
    return this.checkPermission('navAdminTextMacros', () => PermissionHelper.hasAnyRole(this._roles, [
      BASE_ARTA,
    ]));
  }

  get navAdminNews(): boolean {
    return this.checkPermission('navAdminNews', () => PermissionHelper.hasAnyRole(this._roles, [
      BASE_ARTA,
    ]));
  }

  get navAdminDevGoals(): boolean {
    return this.checkPermission('navAdminDevGoals', () => PermissionHelper.hasAnyRole(this._roles, [
      DST_DISTRIBUT,
    ]));
  }

  get navAdminCategories(): boolean {
    return this.checkPermission('navAdminCategories', () => this.navAdminOfflineContent);
  }

  get navAdminCatalog(): boolean {
    return this.checkPermission('navAdminCatalog', () => PermissionHelper.hasAnyRole(this._roles, [
        CAT_ADMIN,
      ]));
  }

  get navAdminUserAdministration(): boolean {
    return this.checkPermission('navAdminUserAdministration', () => this.navAdminUsers ||
        this.navAdminUsersReduced ||
        this.navAdminTargetGroups ||
        this.navAdminUserGroups ||
        this.navAdminSelfRegistration ||
        this.navAdminUsersCSV ||
        this.navAdminReducedUserAdministration ||
        this.navAdminLearnerStateImport);
  }

  get navAdminUsers(): boolean {
    return this.adminUserGeneral;
  }

  get navAdminUsersCSV(): boolean {
    return this.checkPermission('navAdminUsersCSV', () => PermissionHelper.hasAnyRole(this._roles, [
        ucsv_BASE_USR_USER_CSV_IMPORT,
      ]));
  }

  get navAdminSignatures(): boolean {
    return this.checkPermission('navAdminSignatures', () => this.adminNotifications);
  }

  get navAdminTargetGroups(): boolean {
    return this.checkPermission('navAdminTargetGroups', () => PermissionHelper.hasAnyRole(this._roles, [
        BASE_TG_ADMINCREATE,
        BASE_TG_ADMINREAD,
        BASE_TG_ADMINGENERAL,
      ]));
  }

  get navAdminUserAttribute(): boolean {
    return this.checkPermission('navAdminUserAttribute', () => PermissionHelper.hasAnyRole(this._roles, [
        EDIT_FRONTEND_CONFIG,
      ]));
  }

  get navAdminLearnerStateImport(): boolean {
    return this.showAlpha && this.checkPermission('navAdminLearnerStateImport', () =>
      PermissionHelper.hasAnyRole(this._roles, [
        LSTD_IMPORTER,
      ]));
  }

  get navAdminSelfRegistration(): boolean {
    return this.checkPermission('navAdminSelfRegistration', () => PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_SELFT_REGISTRATION
      ]));
  }

  get navAdminUserGroups(): boolean {
    return this.checkPermission('navAdminUserGroups', () => PermissionHelper.hasAnyRole(this._roles, [
        ugar_BASE_USR_GENERAL_USER_ADMIN
      ]));
  }

  get navAdminOffline(): boolean {
    return this.checkPermission('navAdminOffline', () => (
        this.navAdminOfflineContent ||
        this.navAdminOfflineLocation ||
        this.navAdminOfflineProvider ||
        this.navAdminOfflineTrainer ||
        this.navAdminOfflineRecording
      ));
  }

  get navAdminOfflineContent(): boolean {
    return this.checkPermission('navAdminOfflineContent', () => PermissionHelper.hasAnyRole(this._roles, [
        // the permission offnga is required for any offline-content sub-page
        OFF_ADMIN_NEXT_GEN,
    ]) && this.adminOffline);
  }

  get navAdminOfflineRecording(): boolean {
    return this.checkPermission('navAdminOfflineRecording', () => PermissionHelper.hasAnyRole(this._roles, [
      // the permission offnga is required for any offline-content sub-page
      OFF_ADMIN_NEXT_GEN,
    ]) && PermissionHelper.hasAnyRole(this._roles, [
      OFF_ADMIN_GENERAL,
    ]));
  }

  get navAdminOfflineLocation(): boolean {
    return this.checkPermission('navAdminOfflineLocation', () => PermissionHelper.hasAnyRole(this._roles, [
        // the permission offnga is required for any offline-content sub-page
        OFF_ADMIN_NEXT_GEN,
      ]) && PermissionHelper.hasAnyRole(this._roles, [
        OFF_ADMIN_LOCATIONS,
        OFF_ADMIN_GENERAL,
      ]));
  }

  get navAdminOfflineNotification(): boolean {
    return this.navAdminNotifications;
  }

  get navAdminOfflineProvider(): boolean {
    return this.checkPermission('navAdminOfflineProvider', () => PermissionHelper.hasAnyRole(this._roles, [
        // the permission offnga is required for any offline-content sub-page
        OFF_ADMIN_NEXT_GEN,
      ]) && PermissionHelper.hasAnyRole(this._roles, [
        OFF_ADMIN_PROVIDERS,
        OFF_ADMIN_GENERAL,
      ]));
  }

  get navAdminOfflineTrainer(): boolean {
    return this.checkPermission('navAdminOfflineTrainer', () => PermissionHelper.hasAnyRole(this._roles, [
        // the permission offnga is required for any offline-content sub-page
        OFF_ADMIN_NEXT_GEN,
      ]) && PermissionHelper.hasAnyRole(this._roles, [
        OFF_ADMIN_TRAINER,
        OFF_ADMIN_GENERAL,
      ]));
  }

  get navAdminPermissions(): boolean {
    return this.checkPermission('navAdminPermissions', () => this.adminRoles);
  }

  get navAdminReportGenerator(): boolean {
    return this.checkPermission('navAdminReportGenerator', () => this.navAdminAccountDesign &&
        this.navCtrlReportGenerator);
  }

  get navAdminToDo(): boolean {
    return this.checkPermission('navAdminToDo', () => PermissionHelper.hasAnyRole(this._roles, [
        ELEA_CUR_TODO,
        REFLACT,
      ]));
  }

  get navAdminLink(): boolean {
    return this.checkPermission('navAdminLink', () => PermissionHelper.hasAnyRole(this._roles, [
      ELEA_DEPLOY_ADMIN_CREATE,
      ELEA_DEPLOY_COURSES,
      REFLACT,
    ]));
  }

  get navAdminScoDocument(): boolean {
    return this.checkPermission('navAdminDocument', () => PermissionHelper.hasAnyRole(this._roles, [
      ELEA_DEPLOY_ADMIN_CREATE,
      ELEA_DEPLOY_COURSES,
      REFLACT,
    ]));
  }

  get navAdminWbt(): boolean {
    return this.checkPermission('navAdminWbt', () =>  PermissionHelper.hasAnyRole(this._roles, [
      ELEA_DEPLOY_ADMIN_CREATE,
      ELEA_DEPLOY_COURSES,
      REFLACT,
    ]));
  }

  get navAdminTest(): boolean {
    return this.checkPermission('navAdminTest', () => PermissionHelper.hasAnyRole(this._roles, [
      ELEA_DEPLOY_ADMIN_CREATE,
      ELEA_DEPLOY_COURSES,
      REFLACT,
    ]));
  }

  get navClassic(): boolean {
    return this.checkPermission('navClassic', () => !PermissionHelper.hasAnyRole(this._roles, [
        // the permission "ngnc" hides the navigation to classic from next-gen
        NGNC_NO_NAV_TO_CLASSIC,
      ]) && this.navClassicAdmin);
  }

  get navClassicAdmin(): boolean {
    return this.checkPermission('navClassicAdmin', () => PermissionHelper.hasAnyRole(this._roles, [
        BASE_AC_ACTIONCODEADMIN,
        BASE_ACC_ADMIN_CREATE,
        BASE_ACC_ADMIN_EDIT,
        BASE_ACC_ADMIN_READ,
        BASE_AC_ACTIONCODEREDUCEDADMIN,
        BASE_ARTA,
        DST_CONT_GENERAL,
        DST_CONT_LIGHT,
        CAT_ADMIN,
        'cda',
        'cia',
        ELEA_CRS_GENERALS,
        ELEA_CRS_EXCHANGE,
        ELEA_CUR_CREATE,
        ELEA_CUR_GENERAL,
        ELEA_CUR_READ,
        'dco',
        ELEA_DEPLOY_ADMIN_CREATE,
        ELEA_DEPLOY_ADMIN_DELETE,
        ELEA_DEPLOY_COURSES,
        ELEA_DEPLOY_ADMIN_REDEPLOY,
        DOCLIB_ADMIN,
        DOCLIB_FOLDER_ADMIN,
        DST_DISTRIBUT,
        // DST_DIST_LICENCE_GROUP,
        // DST_DIST_LIC_GROUP_FOR_TG,
        'dwf',
        EHS_ADMIN,
        'gaac',
        'gaar',
        JOURNAL_READ,
        ANG_LAYOUT_ADMIN,
        'lireq',
        LICENCE_REQUEST_ONLINE_EDIT,
        LICENCE_REQUEST_ONLINE_READ,
        OFF_ADMIN_GENERAL,
        OFF_CTRL_GENERAL,
        OFF_ADMIN_CONTENT_GENERAL,
        OFF_CTRL_CONTENT_ASSIGNMENT,
        OFF_ADMIN_CONTENT_CREATE,
        OFF_ADMIN_EVENT_GENERAL,
        OFF_CTRL_EVENT_ASSIGN_AND_EDIT_STATUS,
        OFF_ADMIN_EVENT_CREATE,
        OFF_ADMIN_LOCATIONS,
        OFF_ADMIN_TRAINER,
        'oua',
        'par',
        rac_ROLE_ADMIN_CREATE,
        rar_ROLE_ADMIN_GENERAL,
        REFLACT,
        'rpar',
        BASE_USR_REDUCED_USER_CREATE,
        BASE_USR_REDUCED_USER_EDIT,
        BASE_TG_ADMINCREATE,
        BASE_TG_ADMINGENERAL,
        BASE_TG_ADMINREAD,
        'uaac',
        'uaar',
        BASE_USR_USER_CREATE,
        BASE_USR_USER_DELETE,
        ucsv_BASE_USR_USER_CSV_IMPORT,
        ugar_BASE_USR_GENERAL_USER_ADMIN,
        BASE_USR_USER_SERIAL_LETTERS,
        'vra',
        'vrtr',
      ]));
  }

  get navClassicCtrl(): boolean {
    return this.checkPermission('navClassicCtrl', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_clsctrl_CLASSIC,
      ]));
  }

  get navCtrl(): boolean {
    return this.checkPermission('navCtrl', () => this.navCtrlFad ||
        this.navCtrlReportGenerator ||
        this.navCtrlSingleUser ||
        this.navCtrlZuep ||
        this.navCtrlFurtherBoards ||
        this.navCtrlReportDashboard ||
        this.navCtrlSingleLists ||
        this.navCtrlReportSaved ||
        this.navCtrlFad);
  }

  /**
   * @see OfficialsDashboardsComponent
   */
  get navCtrlFad(): boolean {
    return this.checkPermission('navCtrlFad', () => PermissionHelper.hasAnyRole(this._roles, [
        REFLACT,
        REP_GENERAL_READ,
        REP_CREATE,
        REP_READ,
      ]));
  }

  get navCtrlOfflineAssign(): boolean {
    return this.checkPermission('navCtrlOfflineAssign', () => PermissionHelper.hasAnyRole(this._roles, [
        OFF_CTRL_CONTENT_ASSIGNMENT,
        OFF_CTRL_EVENT_ASSIGN_AND_EDIT_STATUS,
        OFF_CTRL_GENERAL,
        OFF_CTRL_EDIT_STATUS_ONLY,
        REFLACT,
      ]));
  }

  get navCtrlReportClassic(): boolean {
    return this.checkPermission('navCtrlReportClassic', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_REPORT_READ,
        'rgu',
      ]));
  }

  get navCtrlReportCourse(): boolean {
    return this.checkPermission('navCtrlReportCourse', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_REPORT_GENERATOR_COURSES,
      ]));
  }

  get navCtrlReportCurriculum(): boolean {
    return this.checkPermission('navCtrlReportCurriculum', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_REPORT_GENERATOR_CURRICULUM,
      ]));
  }

  get navCtrlReportDashboard(): boolean {
    return this.checkPermission('navCtrlReportDashboard', () => this.navCtrlReportGenerator &&
        PermissionHelper.hasAnyRole(this._roles, [
          Controlling_REPORT_GENERATOR_DASHBOARD,
        ]));
  }

  get navCtrlReportGenerator(): boolean {
    return this.checkPermission('navCtrlReportGenerator', () => this.navCtrlReportCourse || this.navCtrlReportCurriculum || this.navCtrlReportOffline);
  }

  get navCtrlReportOffline(): boolean {
    return this.checkPermission('navCtrlReportOffline', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_REPORT_GENERATOR_OFFLINE_corgoff,
      ]));
  }

  get navCtrlReportSaved(): boolean {
    return this.navCtrlReportGenerator ||
      this.navCtrlReportClassic;
  }

  /**
   * @see CtrlUserListComponent
   */
  get navCtrlSingleUser(): boolean {
    return this.checkPermission('navCtrlSingleUser', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_SINGLE_USER_EDIT,
        Controlling_SINGLE_USER_READ,
        Controlling_SINGLE_USER_IN_TG_EDIT,
        Controlling_SINGLE_USER_IN_TG_READ,
      ]));
  }

  /**
   * @see ???
   */
  get navCtrlSingleLists(): boolean {
    return this.checkPermission('navCtrlSingleLists', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_SINGLE_LISTS_ngsinlis,
      ]));
  }

  /**
   * @see OfficialsDashboardsComponent
   */
  get navCtrlTrainerDashboard(): boolean {
    return this.checkPermission('navCtrlTrainerDashboard', () => PermissionHelper.hasAnyRole(this._roles, [
      OFF_CTRL_GENERAL,
      OFF_CTRL_EDIT_STATUS_ONLY,
      OFF_CTRL_CONTENT_ASSIGNMENT,
      OFF_CTRL_EVENT_ASSIGN_AND_EDIT_STATUS,
    ]));
  }

  /**
   * @see ZuepComponent
   */
  get navCtrlZuep(): boolean {
    return this.checkPermission('navCtrlZuep', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_ZUEP,
        REFLACT,
      ]));
  }

  get navCtrlFurtherBoards(): boolean {
    return this.checkPermission('navCtrlFurtherBoards', () => PermissionHelper.hasAnyRole(this._roles, [
        Controlling_FURTHER_BOARDS,
        Controlling_FURTHER_BOARD_01,
        Controlling_FURTHER_BOARD_02,
        Controlling_FURTHER_BOARD_03,
        Controlling_FURTHER_BOARD_04,
        REFLACT,
      ]));
  }

  get navCatalog(): boolean {
    return /*!this._hideForIlearn &&*/ this.checkPermission('navCatalog', () => PermissionHelper.hasAnyRole(this._roles, [
        CAT_USER,
      ]));
  }

  get navQA(): boolean {
    return !this._hideForIlearn && this.checkPermission('navQA', () => PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_QA_READ,
        NEXT_GEN_QA_MODERATOR
      ]));
  }

  get navNewQA(): boolean {
    // todo add permissions
    return !this._hideForIlearn && this.checkPermission('navNewQA', () => PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_QA_READ,
        NEXT_GEN_QA_MODERATOR,
        NEXT_GEN_QA_ASK_QUESTION,
      ]));
  }

  get navDocLib(): boolean {
    return this.checkPermission('navDocLib', () => PermissionHelper.hasAnyRole(this._roles, [
        DOCLIB_ADMIN,
        DOCLIB_FOLDER_ADMIN,
        DOCLIB_USER,
      ]));
  }

  get adminCatalogPublishTo(): boolean {
    return this.checkPermission('adminCatalogPublishTo', () => PermissionHelper.hasAnyRole(this._roles, [
        CAT_ADMIN,
      ]));
  }

  get navUser(): boolean {
    return this.checkPermission('navUser', () => this.navUserCertificates ||
        this.navUserLearn);
  }

  get navUserCertificates(): boolean {
    return this.checkPermission('navUserCertificates', () => PermissionHelper.hasAnyRole(this._roles, [
        ELEA_MY_CERTS,
        REFLACT,
      ]));
  }

  get navUserLearn(): boolean {
    return this.checkPermission('navUserLearn', () => this.navUserLearnMandatory ||
        this.navUserLearnVoluntary ||
        this.navUserLearnOfflineContent ||
        this.navUserCertificates);
  }

  get navUserLearnMandatory(): boolean {
    return this.checkPermission('navUserLearnMandatory', () => !this.navUserLearnOfflineContent && PermissionHelper.hasAnyRole(this._roles, [
        ELEA_LEARNER,
        ELEA_MT_MAN_COURSES,
      ]));
  }

  get navUserLearnVoluntary(): boolean {
    return this.checkPermission('navUserLearnVoluntary', () => !this.navUserLearnOfflineContent && PermissionHelper.hasAnyRole(this._roles, [
        ELEA_LEARNER,
        ELEA_MT_VOL_COURSES,
      ]));
  }

  /**
   * Mode-switch to replace mandatory / voluntary with virtual / offline
   */
  get navUserLearnOfflineContent(): boolean {
    return !this._hideForIlearn && this.checkPermission('navUserLearnOfflineContent', () => PermissionHelper.hasAllRoles(this._roles, [
        ELEA_LEARNER,
        ELEA_MT_OFFLINE_CONTENT,
      ]));
  }

  /**
   * @see LinkedUsersComponent
   */
  get navUserLink(): boolean {
    return this.checkPermission('navUserLink', () => PermissionHelper.hasAnyRole(this._roles, [
        BASE_UPID,
        // REFLACT,
      ]));
  }

  get navUserMessages(): boolean {
    return this.checkPermission('navUserMessages', () => PermissionHelper.hasAnyRole(this._roles, [
        MSG_READ_MSGS,
        REFLACT,
      ]));
  }

  get navUserContact(): boolean {
    return this.checkPermission('navUserContact', () => !PermissionHelper.hasAnyRole(this._roles, [
        MSG_READ_MSGS,
        REFLACT,
      ]));
  }

  get alwaysHide(): boolean {
    return this.checkPermission('alwaysHide', () => false);
  }

  get navUserTransactions(): boolean {

    // ToDo add permissions
    return !this._hideForIlearn && this.showAlpha;
  }

  get ngCustomColors(): boolean {

    if ( !this.hideForILearn ) {
      // TF-3836 skip permission checks for anything other than iLearn
      return true;
    }

    return this.checkPermission('ngCustomColors', () => PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_CUSTOM_COLORS,
        REFLACT,
      ]));
  }

  get ngCustomColorsAdmin(): boolean {

    if ( !this.hideForILearn ) {
      // TF-3836 skip permission checks for anything other than iLearn
      return true;
    }

    return this.checkPermission('ngCustomColorsAdmin', () => PermissionHelper.hasAnyRole(this._roles, [
        NEXT_GEN_CUSTOM_COLORS_ADMIN,
        REFLACT,
      ]));
  }

  get userMessagesPlanned(): boolean {
    return this.checkPermission('userMessagesPlanned', () => this.navUserMessages &&
        PermissionHelper.hasAnyRole(this._roles, [
          MSG_TIMED_MSGS,
          REFLACT,
        ]));
  }

  get userMessagesSaveDraft(): boolean {
    return this.checkPermission('userMessagesSaveDraft', () => this.navUserMessages &&
        PermissionHelper.hasAnyRole(this._roles, [
          MSG_SAVE_DRAFTS,
          REFLACT,
        ]));
  }

  get userMessagesSend(): boolean {
    return this.checkPermission('userMessagesSend', () => this.navUserMessages &&
        PermissionHelper.hasAnyRole(this._roles, [
          MSG_WRITE_MSGS,
          MSG_WRITE_MSGS_TO_OWN_CC,
          MSG_WRITE_MSGS_TO_TG,
        ]));
  }

  get userMessagesSendReport(): boolean {
    return this.checkPermission('userMessagesSendReport', () => this.navUserMessages &&
        PermissionHelper.hasAnyRole(this._roles, [
          Controlling_REPORT_READ,
          REFLACT,
        ]));
  }

  get isWBTTrackingEnabled(): boolean {
    return this.checkPermission('isWBTTrackingEnabled', () => !PermissionHelper.hasAnyRole(this._roles, [
        SCORM_TRACKING_IS_OFF,
      ]));
  }

  get userProfileDeactivate(): boolean {
    return this.checkPermission('userProfileDeactivate', () => PermissionHelper.hasAnyRole(this._roles, [
      USR_PROFILE_DEACTIVATE,
    ]));
  }

  /**
   * see com.reflact.sphere.elearning.deployment.DeployCoursesAction#deleteCourse
   */
  getAdminCourseDelete(objectPermissions: string[] = []): boolean {
    return PermissionHelper.hasAnyRole(objectPermissions, [
      ELEA_DEPLOY_ADMIN_DELETE,
    ]) || PermissionHelper.hasAnyRole(this._roles, [
      REFLACT,
      ELEA_CRS_GENERALS,
    ]);
  }

  /**
   * see com.reflact.sphere.elearning.deployment.DeployCoursesAction#redeployCourse
   */
  getAdminCourseRedeploy(objectPermissions: string[] = []): boolean {
    return PermissionHelper.hasAnyRole(objectPermissions, [
      ELEA_DEPLOY_ADMIN_REDEPLOY,
      ELEA_CRS_EXCHANGE,
    ]) || PermissionHelper.hasAnyRole(this._roles, [
      REFLACT,
      ELEA_CRS_GENERALS,
    ]);
  }

  getAdminTargetGroupDelete(objectPermissions: string[] = []): boolean {
    return this.adminTargetGroupGeneral || PermissionHelper.hasAnyRole(objectPermissions, [
      BASE_TG_ADMINDELETE,
    ]);
  }

  getAdminTargetGroupEdit(objectPermissions: string[] = []): boolean {
    return this.adminTargetGroupGeneral || PermissionHelper.hasAnyRole(objectPermissions, [
      BASE_TG_ADMINCREATE,
      BASE_TG_ADMINFILTERCREATE,
    ]);
  }

  getAdminTargetGroupRead(objectPermissions: string[] = []): boolean {
    return this.adminTargetGroupGeneral || PermissionHelper.hasAnyRole(objectPermissions, [
      BASE_TG_ADMINREAD,
    ]);
  }

  getAnyOfNavUserLearn() {
    return this.navUserLearn ||
      this.navUserLearnMandatory ||
      this.navUserLearnVoluntary ||
      this.navUserLearnOfflineContent ||
      this.navUserLearn;
  }

  get navAdminSuperiorFeedback() {
    return this.checkPermission('navAdminSuperiorFeedback', () =>
        PermissionHelper.hasAnyRole(this._roles, [
          SUPERIOR_FEEDBACK_ADMIN
        ]));
  }

  get navSuperiorFeedback() {
    return this.checkPermission('navSuperiorFeedback', () =>
        PermissionHelper.hasAnyRole(this._roles, [
          SUPERIOR_FEEDBACK_SUPERIOR
        ]));
  }

  get journalRead() {
    return this.checkPermission('journalRead', () =>
        PermissionHelper.hasAnyRole(this._roles, [
          JOURNAL_READ
        ]));
  }

  get navAdminWacSyllabiRead() {
    return this.checkPermission('navAdminWacSyllabiRead', () =>
        (this._environment.featuresEnabled?.syllabus === true) &&
        PermissionHelper.hasAnyRole(this._roles, [
          ELEA_CUR_CREATE,
          ELEA_CUR_READ,
        ]));
  }

  get navApiDocs() {
    return this.checkPermission('navApiDocs', () =>
        PermissionHelper.hasAnyRole(this._roles, [
          BASE_ACC_ADMIN_READ
        ]));
  }

  get navApiDocsCustom() {
    return this.checkPermission('navApiDocs', () =>
      (this._environment.featuresEnabled?.syllabus === true) &&
      PermissionHelper.hasAnyRole(this._roles, [
        BASE_ACC_ADMIN_READ
      ]));
  }

  private checkPermission(path: string, check: () => boolean): boolean {
    if ( this._cache.hasOwnProperty(path) ) {
      return this._cache[path];
    }
    return this._cache[path] = check();
  }

}
