<rag-content-autocomplete-v2 class="autocomplete" *ngIf="showContentSearch"></rag-content-autocomplete-v2>

<!--<button mat-button type="button" *ngIf="!isGtMedium && menuItemsStack?.length > 1" (click)="showLastMenu()">-->
<!--  <mat-icon svgIcon="chevron-left"></mat-icon>-->
<!--  <span i18n="@@global_back">Back</span>-->
<!--</button>-->

<button class="back-button" mat-button type="button" *ngIf="backButtonVisible" (click)="showLastMenu()">
  <mat-icon svgIcon="chevron-left"></mat-icon>
  <span i18n="@@global_back">Back</span>
</button>

<mat-divider *ngIf="backButtonVisible"></mat-divider>
<ng-container *ngFor="let navItem of navItems">

  <a
    *ngIf="navItem.url == null && headerNavHelper.asLinkIcon(navItem) as link"
    class="stacked" mat-button
    [routerLink]="link.routerLink"
    [queryParams]="link.queryParams"
    routerLinkActive="active"
    [routerLinkActiveOptions]="link.routerLinkActiveOptions"
    [target]="link.external ? '_blank' : null"
    [matTooltipDisabled]="!onlyIcons"
    [matTooltip]="link.title | localeText"
    [matTooltipPosition]="'right'"
  >
    <div class="additional-routes" *ngIf="link.additionalRoutes?.length > 0">
      <div *ngFor="let route of link.additionalRoutes" [routerLink]="route"></div>
    </div>
    <span class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <div class="center-horizontal">
        <img
          *ngIf="link.icon !== undefined"
          [src]="link.icon"
          class="icon"
          alt=""
        >
        <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>
      </div>
      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>
    </span>
  </a>

  <a
    *ngIf=" navItem?.url != null && headerNavHelper.asLinkIcon(navItem) as link"
    class="stacked" mat-button
    [href]="link.url"
    [target]="link.external ? '_blank' : null"
    [matTooltipDisabled]="!onlyIcons"
    [matTooltip]="link.title | localeText"
    [matTooltipPosition]="'right'"
  >
    <span class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <div class="center-horizontal">
        <img
          *ngIf="link.icon !== undefined"
          [src]="link.icon"
          class="icon"
          alt=""
        >
        <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>
      </div>
      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>
    </span>
  </a>

  <a
    *ngIf="navItem.type !== 'linkIcon' && headerNavHelper.asLink(navItem) as link"
    class="stacked" mat-button
    [routerLink]="link.routerLink"
    [queryParams]="link.queryParams"
    routerLinkActive="active"
    [routerLinkActiveOptions]="link.routerLinkActiveOptions"
    [target]="link.external ? '_blank' : null"
    [matTooltipDisabled]="!onlyIcons"
    [matTooltip]="link.title | localeText"
    [matTooltipPosition]="'right'"
  >
    <div class="additional-routes" *ngIf="link.additionalRoutes?.length > 0">
      <div *ngFor="let route of link.additionalRoutes" [routerLink]="route"></div>
    </div>
    <span class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>
      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>
    </span>
  </a>


  <a
    *ngIf="navItem.type !== 'linkIcon' && headerNavHelper.asLinkHref(navItem) as link"
    class="stacked" mat-button
    [href]="link.url"
    [target]="link.external ? '_blank' : null"
    [matTooltipDisabled]="!onlyIcons"
    [matTooltip]="link.title | localeText"
    [matTooltipPosition]="'right'"
  >
    <span class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>
      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>
    </span>
  </a>

  <!--  <a-->
<!--    *ngIf="headerNavHelper.asLinkIcon(navItem) as link"-->
<!--    class="stacked" mat-button-->
<!--    [href]="link.url"-->
<!--    [target]="link.external ? '_blank' : null"-->
<!--    [matTooltipDisabled]="!onlyIcons"-->
<!--    [matTooltip]="link.title | localeText"-->
<!--    [matTooltipPosition]="'right'"-->
<!--  >-->
<!--    <span class="title-wrapper">-->
<!--      <div>-->
<!--        <mat-icon *ngIf="navItem.mainIcon !== undefined" [svgIcon]="navItem.mainIcon" class="icon"></mat-icon>-->
<!--        <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>-->
<!--      </div>-->
<!--      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>-->
<!--    </span>-->
<!--  </a>-->

  <button
    *ngIf="headerNavHelper.asMenuIcon(navItem) as menu"
    class="stacked" mat-button type="button"
    routerLinkActive="active"
    [routerLinkActiveOptions]="menu.routerLinkActiveOptions"
    (click)="onMenuOpen(menu)"
    [matTooltipDisabled]="!onlyIcons"
    [matTooltip]="navItem.title | localeText"
    [matTooltipPosition]="'right'"
  >
    <div class="additional-routes" *ngIf="menu.additionalRoutes?.length > 0">
      <div *ngFor="let route of menu.additionalRoutes" [routerLink]="route"></div>
    </div>
    <div class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <!--      <code>'{{navItem.mainIcon !== undefined}}'</code>-->
      <div class="center-horizontal">
        <img *ngIf="menu.icon !== undefined" [src]="menu.icon" class="icon" alt="">
        <span *ngIf="!onlyIcons" class="link-title">{{navItem.title}}</span>
      </div>
      <mat-icon svgIcon="chevron-right" *ngIf="!onlyIcons"></mat-icon>
    </div>
  </button>

  <button
    *ngIf="navItem.type !== 'menuIcon' && headerNavHelper.asMenu(navItem) as menu"
    class="stacked" mat-button type="button"
    routerLinkActive="active"
    [routerLinkActiveOptions]="menu.routerLinkActiveOptions"
    (click)="onMenuOpen(menu)"
    [matTooltipDisabled]="!onlyIcons"
    [matTooltip]="navItem.title | localeText"
    [matTooltipPosition]="'right'"
  >
    <div class="additional-routes" *ngIf="menu.additionalRoutes?.length > 0">
      <div *ngFor="let route of menu.additionalRoutes" [routerLink]="route"></div>
    </div>
    <div class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <!--      <code>'{{navItem.mainIcon !== undefined}}'</code>-->
      <span *ngIf="!onlyIcons" class="link-title">{{navItem.title}}</span>
      <mat-icon svgIcon="chevron-right" *ngIf="!onlyIcons"></mat-icon>
    </div>
  </button>

</ng-container>

<mat-divider class="divider" *ngIf="isSmall && topItems.length > 0"></mat-divider>
<ng-container *ngIf="isSmall">
  <ng-container *ngFor="let navItem of topItems">
<!--    <ng-container *ngIf="!onlyIcons && headerNavHelper.getLogin(navItem) as login">-->
<!--      <a mat-button [disabled]="true" class="login-container">-->
<!--        <span class="item-title login">{{login}}</span>-->
<!--      </a>-->
<!--    </ng-container>-->

    <a
      *ngIf="headerNavHelper.asLinkIcon(navItem) as link"
      class="stacked" mat-button
      [routerLink]="link.routerLink"
      [queryParams]="link.queryParams"
      routerLinkActive="active"
      [routerLinkActiveOptions]="link.routerLinkActiveOptions"
      [target]="link.external ? '_blank' : null"
      [matTooltipDisabled]="!onlyIcons"
      [matTooltip]="link.title | localeText"
      [matTooltipPosition]="'right'"
    >
      <div class="additional-routes" *ngIf="link.additionalRoutes?.length > 0">
        <div *ngFor="let route of link.additionalRoutes" [routerLink]="route"></div>
      </div>
      <span class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <div class="center-horizontal">
        <mat-icon *ngIf="link.icon !== undefined" [svgIcon]="link.icon" class="icon icon-center"></mat-icon>
        <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>
      </div>
      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>
    </span>
    </a>

    <!--  <a-->
    <!--    *ngIf="headerNavHelper.asLinkIcon(navItem) as link"-->
    <!--    class="stacked" mat-button-->
    <!--    [href]="link.url"-->
    <!--    [target]="link.external ? '_blank' : null"-->
    <!--    [matTooltipDisabled]="!onlyIcons"-->
    <!--    [matTooltip]="link.title | localeText"-->
    <!--    [matTooltipPosition]="'right'"-->
    <!--  >-->
    <!--    <span class="title-wrapper">-->
    <!--      <div>-->
    <!--        <mat-icon *ngIf="navItem.mainIcon !== undefined" [svgIcon]="navItem.mainIcon" class="icon"></mat-icon>-->
    <!--        <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>-->
    <!--      </div>-->
    <!--      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>-->
    <!--    </span>-->
    <!--  </a>-->

    <button
      *ngIf="headerNavHelper.asMenuIcon(navItem) as menu"
      class="stacked" mat-button type="button"
      routerLinkActive="active"
      [routerLinkActiveOptions]="menu.routerLinkActiveOptions"
      (click)="onMenuOpen(menu)"
      [matTooltipDisabled]="!onlyIcons"
      [matTooltip]="navItem.title | localeText"
      [matTooltipPosition]="'right'"
    >
      <div class="additional-routes" *ngIf="menu.additionalRoutes?.length > 0">
        <div *ngFor="let route of menu.additionalRoutes" [routerLink]="route"></div>
      </div>
      <div class="title-wrapper" [ngClass]="{'center':onlyIcons}">
        <!--      <code>'{{navItem.mainIcon !== undefined}}'</code>-->
        <div class="center-horizontal">
          <mat-icon *ngIf="menu.icon !== undefined" [svgIcon]="menu.icon" class="icon icon-center"></mat-icon>
          <span *ngIf="!onlyIcons" class="link-title">{{navItem.title}}</span>
        </div>
        <mat-icon svgIcon="chevron-right" *ngIf="!onlyIcons"></mat-icon>
      </div>
    </button>

    <button
      *ngIf="headerNavHelper.asButtonIcon(navItem) as buttonIcon"
      type="button" class="stacked" mat-button
      (click)="headerNavHelper.onNavItemClick(buttonIcon)"
    >
    <span class="title-wrapper" [ngClass]="{'center':onlyIcons}">
      <div class="center-horizontal">
        <mat-icon
          *ngIf="buttonIcon.icon !== undefined"
          [svgIcon]="buttonIcon.icon"
          class="icon icon-center"

        ></mat-icon>
        <span *ngIf="!onlyIcons" class="link-title">{{buttonIcon.title}}</span>
      </div>
    </span>
    </button>
    <!--  <a-->
    <!--    *ngIf="headerNavHelper.asLink(navItem) as link"-->
    <!--    class="stacked" mat-button-->
    <!--    [routerLink]="link.routerLink"-->
    <!--    [queryParams]="link.queryParams"-->
    <!--    routerLinkActive="active"-->
    <!--    [routerLinkActiveOptions]="link.routerLinkActiveOptions"-->
    <!--    [target]="link.external ? '_blank' : null"-->
    <!--    [matTooltipDisabled]="!onlyIcons"-->
    <!--    [matTooltip]="link.title | localeText"-->
    <!--    [matTooltipPosition]="'right'"-->
    <!--  >-->
    <!--    <div class="additional-routes" *ngIf="link.additionalRoutes?.length > 0">-->
    <!--      <div *ngFor="let route of link.additionalRoutes" [routerLink]="route"></div>-->
    <!--    </div>-->
    <!--    <span class="title-wrapper">-->
    <!--      <div>-->
    <!--        <mat-icon-->
    <!--          *ngIf="navItem.mainIcon !== undefined"-->
    <!--          [svgIcon]="navItem.mainIcon"-->
    <!--          class="icon"-->
    <!--        ></mat-icon>-->
    <!--        <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>-->
    <!--      </div>-->
    <!--      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>-->
    <!--    </span>-->
    <!--  </a>-->

    <!--  <a-->
    <!--    *ngIf="headerNavHelper.asLinkHref(navItem) as link"-->
    <!--    class="stacked" mat-button-->
    <!--    [href]="link.url"-->
    <!--    [target]="link.external ? '_blank' : null"-->
    <!--    [matTooltipDisabled]="!onlyIcons"-->
    <!--    [matTooltip]="link.title | localeText"-->
    <!--    [matTooltipPosition]="'right'"-->
    <!--  >-->
    <!--    <span class="title-wrapper">-->
    <!--      <div>-->
    <!--        <mat-icon *ngIf="navItem.mainIcon !== undefined" [svgIcon]="navItem.mainIcon" class="icon"></mat-icon>-->
    <!--        <span *ngIf="!onlyIcons" class="link-title">{{link.title}}</span>-->
    <!--      </div>-->
    <!--      <mat-icon *ngIf="link.external && !onlyIcons" class="size-16" svgIcon="open-in-new"></mat-icon>-->
    <!--    </span>-->
    <!--  </a>-->

    <!--  <button-->
    <!--    *ngIf="headerNavHelper.asMenu(navItem) as menu"-->
    <!--    class="stacked" mat-button type="button"-->
    <!--    routerLinkActive="active"-->
    <!--    [routerLinkActiveOptions]="menu.routerLinkActiveOptions"-->
    <!--    (click)="onMenuOpen(menu)"-->
    <!--    [matTooltipDisabled]="!onlyIcons"-->
    <!--    [matTooltip]="navItem.title | localeText"-->
    <!--    [matTooltipPosition]="'right'"-->
    <!--  >-->
    <!--    <div class="additional-routes" *ngIf="menu.additionalRoutes?.length > 0">-->
    <!--      <div *ngFor="let route of menu.additionalRoutes" [routerLink]="route"></div>-->
    <!--    </div>-->
    <!--    <div class="title-wrapper">-->
    <!--      &lt;!&ndash;      <code>'{{navItem.mainIcon !== undefined}}'</code>&ndash;&gt;-->
    <!--      <div>-->
    <!--        <mat-icon *ngIf="navItem.mainIcon !== undefined" [svgIcon]="navItem.mainIcon" class="icon"></mat-icon>-->
    <!--        <span *ngIf="!onlyIcons" class="link-title">{{navItem.title}}</span>-->
    <!--      </div>-->
    <!--      <mat-icon svgIcon="chevron-right" *ngIf="!onlyIcons"></mat-icon>-->
    <!--    </div>-->
    <!--  </button>-->
  </ng-container>
</ng-container>

<ng-container *ngIf="!onlyIcons && showUsername && headerNavHelper.getLogin(login) as login">
  <div class="login-container">
    <span class="item-title login">{{login}}</span>
  </div>
</ng-container>

<!--<nav-->
<!--  rag-top-navigation-->
<!--  [navItems]="topItems"-->
<!--&gt;</nav>-->
